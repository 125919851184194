import React, { useState, useEffect } from "react";
import { Button, Checkbox, DatePicker, Input, Select } from "antd";
import back5 from "../../../imgs/back5.png";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import cartAni from "../../../imgs/cartAni.gif";
import ConfirmOrder from "../../../imgs/CartDone.svg";
import chatIcon from "../../../imgs/chatIcon.svg";
import ErrorModal from "../../Merchant/Modals/errorModal";
import VerifyOrder from "./verifyOrder";

import Nav from "../vendor/navbar";
import axios from "axios";
import moment from "moment/moment";
const EventDetails = () => {
  const { Option } = Select;
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const [exceedPurchaseLimit, setExceedPurchaseLimit] = useState(false);
  const ProductTaxes = useSelector((state) => state.ProductTaxes);
  const PlaceOrderData = useSelector((state) => state.PlaceOrderData);
  const [err, setErr] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const router = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartData);
  const token = useSelector((state) => state.token);
  const [localCart, setCart] = useState(cart);
  const [initial, setinitial] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState(null);

  const [selectionMethod, setSelectionMethod] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [scheduledDate, setScheduledDate] = useState(null);
  const [show, setShow] = useState(false);
  const [signature, setSignature] = useState("");
  const [display, setDisplay] = useState(null);
  const [showDone, setShowDone] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const SelectedCompanyVendor = useSelector(
    (state) => state.SelectedCompanyVendor
  );
  const SelectedBranchVendor = useSelector(
    (state) => state.SelectedBranchVendor
  );
  const companyId = SelectedCompanyVendor?.id;

  const image = SelectedBranchVendor
    ? SelectedBranchVendor?.logo
    : SelectedCompanyVendor?.logo;
  const name = SelectedBranchVendor
    ? SelectedBranchVendor?.name
    : SelectedCompanyVendor?.name;

  const email = SelectedBranchVendor
    ? SelectedBranchVendor?.primaryEmail
    : SelectedCompanyVendor?.vendorUser?.email;
  const phone = SelectedBranchVendor
    ? SelectedBranchVendor?.primaryPhone
    : SelectedCompanyVendor?.vendorUser?.countryCode !== null
    ? `${SelectedCompanyVendor?.vendorUser?.countryCode} ${
        SelectedCompanyVendor?.vendorUser?.phoneNo ?? ""
      }`
    : "----";
  const profileData = useSelector((state) => state.profileData);
  console.log(profileData);

  // //Get Profile data
  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/v1/users/my-profile/`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((data) => {
  //       dispatch({ type: "setProfileData", num: data?.data });
  //     })
  //     .catch(() => {});
  // }, []);

  const GetPurchaseLimit = () => {
    setExceedPurchaseLimit(false);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-purchase-limit/get-limit-of-buyer?employeeRole=${profileData?.employeeInfo?.role}&mhsssfacilityId=${PlaceOrderData?.mhsssfacilityId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        if (data?.data === null) {
          setExceedPurchaseLimit(false);
        } else {
          setExceedPurchaseLimit(true);
        }
        setShowVerify(true);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (profileData?.employeeInfo) {
      const data = profileData?.employeeInfo;

      setSignature(data?.signature);
      const body = [data?.signature];
      if (data?.signature) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            setDisplay(data?.data?.[0]?.url);
          })
          .catch(() => {});
      }
    }
  }, [profileData]);

  const handleOptionChange = (e) => {
    setSelectionMethod(e.target.value);
  };

  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);

  //Get Listing
  useEffect(() => {
    // const delay = 1000;
    const delay = 0;
    const timeoutId = setTimeout(() => {
      const params = {
        sortBy: "createdAt:desc",
        buyerProgram: "senior-citizen-Pension",
        vendorcompanyId: companyId,
      };

      fetchAllAccess(params);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const fetchAllAccess = (params) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-payment-terms/list-for-buyer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      )
      .then((res) => {
        let wow = [];

        res?.data?.rows.map((item, index) => {
          wow.push({
            value: item?.id,
            label: item?.method,
          });
        });
        setPaymentTerms(res?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const placeOrder = () => {
    const orderItems = localCart.map((item) => ({
      invproductId: item?.id,
      actualUnits: item?.quantity,
    }));

    // Create a deep copy of PlaceOrderData
    const body = {
      orderItems,
      order: JSON.parse(JSON.stringify(PlaceOrderData)), // Deep copy
    };

    if (SelectedBranchVendor) {
      body.order.vendorbranchId = SelectedBranchVendor?.id;
    } else {
      body.order.vendorcompanyId = companyId;
    }

    // Delete unnecessary properties from the copied object
    delete body?.order?.type;
    delete body?.order?.invpaymenttermLabel;
    delete body?.order?.mhsssLabel;
    delete body?.order?.paymentMethod;
    delete body?.order?.paymentMethodLabel;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/inv-order`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if(exceedPurchaseLimit){
          router("/Inventory-Vendor-Orders")
          return
        }
        dispatch({ type: "NEW_ORDER_DATA", num: response?.data });
        router("/PaymentOrder");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  const methods = [
    {
      label: "SAP-Wallet",
      value: "sap-wallet",
    },
    {
      label: "Bank",
      value: "bank",
    },

    {
      label: "Cash",
      value: "cash via QR",
    },

    // {
    //   label: "Cheque",
    //   value: "cheque",
    // },
  ];

  // Calculate subtotal based on conditions
  const subtotal = localCart?.reduce((total, item) => {
    const itemPrice =
      item?.type === "basePrice" ? item?.price : item?.unitPrice || 0; // Choose price or unitPrice
    return total + itemPrice * (item?.quantity || 0); // Multiply by quantity and add to total
  }, 0);

  const totalDelivery = localCart?.reduce((total, item) => {
    return total + (item?.delivery || 0); // Add delivery charges to total, defaulting to 0 if not available
  }, 0);

  const totalDiscount = localCart?.reduce((total, item) => {
    return total + (item?.discount || 0); // Add discount to total, defaulting to 0 if not available
  }, 0);

  const totalTaxes = localCart?.reduce((total, item) => {
    return total + (item?.taxes || 0); // Add discount to total, defaulting to 0 if not available
  }, 0);

  const total = subtotal + totalDelivery + totalTaxes - totalDiscount;

  const orderData = [
    { label: "Delivery Address", value: PlaceOrderData?.mhsssLabel },
    { label: "Program", value: PlaceOrderData?.program },
    {
      label: "Delivery Date",
      value: PlaceOrderData?.deliveryRequiredOn
        ? moment(PlaceOrderData?.deliveryRequiredOn).format("MM/DD/YYYY")
        : "-----",
    },
  ];
  const formatName = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const options = paymentTerms?.map((item) => ({
    value: item?.id,
    label: item?.method,
    downPayPercent: item?.downPayPercent,
    netDays: item?.netDays,
    // other: [item?.downPayPercent, item?.netDays].filter(Boolean).join(", "),
  }));

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex flex-col">
        <h3 className="text-gray-800 font-semibold capitalize text-[1rem]">
          {formatName(option?.label)}
        </h3>
        <div className=" text-gray-700">
          <span className={`${option?.downPayPercent ? "" : "hidden"}`}>
            Down Payment- {option?.downPayPercent}%{" "}
          </span>
          <span className={`${option?.netDays ? "" : "hidden"}`}>
            Credit duration- {option?.netDays} Days
          </span>
        </div>
      </div>
    </Option>
  );

  const OrderDetails = () => {
    return (
      <div className=" rounded-md  w-full  mt-5 px-4">
        <div className="space-y-3">
          {orderData.map((item, index) => (
            <div
              key={index}
              className="flex justify-between text-[0.94rem] gap-10"
            >
              <span className="text-gray-800 font-semibold">{item.label}</span>
              <span className="text-gray-500 font-semibold">{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-[#D9EFED] h-screen">
      <Nav />
      <div className="flex w-full pb-[5rem] items-center justify-center bg-[#D9EFED]">
        <div className="sm:w-[92%] flex flex-col items-center ">
          <div className="w-full flex items-center justify-between">
            <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
              <div
                onClick={() => {
                  router("/Inventory-Cart");
                }}
                className="cursor-pointer flex items-center justify-center"
              >
                <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
                <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                  Purchase order
                </h2>
              </div>
            </div>
          </div>

          {localCart.length > 0 && (
            <div className="p-2 mt-6 rounded-xl  w-[95%] px-5">
              {/* grid grid-cols-[60%_40%] w-full gap-5 */}
              <div className="w-full ">
                <div className="bg-white p-6 px-12 rounded-lg">
                  <div className="w-full">
                    {/* Vendor Deatil */}
                    <div className="bg-white flex  justify-between p-4 rounded-md ">
                      {/* Left side - Buyer Image and Info */}
                      <div className="flex items-center space-x-4 ">
                        <img
                          src={image}
                          alt="Buyer Name"
                          className="w-20 h-20 rounded-full"
                        />
                        <div>
                          <div className="flex items-center space-x-4">
                            <h2 className="text-lg font-bold">{name}</h2>{" "}
                            <img
                              src={chatIcon}
                              alt="chat"
                              className="w-5 h-5 inline-block ml-1"
                            />
                          </div>

                          {/* <h4>
                      Branch Name{" "}
                      <span className="font-semibold text-primary">
                        Yup Branch
                      </span>
                    </h4> */}
                          <p className="text-sm text-gray-600">{phone}</p>
                          <p className="text-sm text-gray-600">{email}</p>
                        </div>
                      </div>
                    </div>

                    <OrderDetails />
                  </div>

                  <div className="mt-6 p-4 w-full bg-[#F6F6F6] rounded-xl text-[0.92rem]">
                    <div className="flex w-full justify-between mb-2">
                      <span className="font-semibold">Total Items</span>
                      <span className="font-semibold">{localCart?.length}</span>
                    </div>
                    <div className="flex w-full justify-between mb-2">
                      <span className="font-semibold">Sub Total </span>
                      <span className="font-semibold">
                        ${subtotal?.toFixed(2)}
                      </span>
                    </div>

                    <div className="flex justify-between mb-2">
                      <span className="font-semibold">
                        Total Delivery Charges
                      </span>
                      <span className="font-semibold">
                        ${totalDelivery?.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between mb-2">
                      <span className="font-semibold">Discount</span>
                      <span className="font-semibold">
                        ${totalDiscount?.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between mb-2">
                      <span className="font-semibold">Taxes</span>
                      <span className="font-semibold">
                        ${totalTaxes?.toFixed(2)}
                      </span>
                    </div>
                    <div
                      className={`${
                        ProductTaxes.length > 0
                          ? "bg-[#EDEDED] px-6 py-2 text-gray-600"
                          : "hidden"
                      }`}
                    >
                      {ProductTaxes &&
                        ProductTaxes?.slice()
                          ?.reverse()
                          ?.map((item, index) => (
                            <div
                              key={index}
                              className="flex justify-between mb-2"
                            >
                              <span className="font-semibold">
                                {item?.name} (
                                {item?.taxPercent
                                  ? Math.round(item?.taxPercent)
                                  : 0}
                                %)
                              </span>
                              <span className="font-semibold">
                                ${item?.totalTax}
                              </span>
                            </div>
                          ))}
                    </div>
                    <div className="flex justify-between font-bold text-lg mt-3">
                      <span>Total</span>
                      <span>${total?.toFixed(2)}</span>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 mt-6">
                    <h1 className="font-semibold text-[1rem]">
                      Payment Method
                    </h1>
                    <div className="flex gap-1 items-center justify-between text-[0.9rem] text-gray-600">
                      <h1 className="font-semibold text-[1rem]">
                        Payment Terms
                      </h1>
                      <div className="flex gap-2 w-[70%]  items-center justify-center">
                        <Select
                          placeholder="Select Payment Term"
                          className="w-[100%] woww"
                          value={selectedPaymentTerms}
                          allowClear // This adds the "X" clear icon
                          onChange={(value, option) => {
                            if (value == null) {
                              // If cleared, remove the selected payment term
                              setSelectedPaymentTerms(null);
                              dispatch({
                                type: "PLACE_ORDER_DATA",
                                num: {
                                  ...PlaceOrderData,
                                  invpaymenttermId: undefined,
                                  invpaymenttermLabel: "",
                                },
                              });
                              dispatch({
                                type: "SELECTED_PAYMENT_TERM",
                                num: null, // Dispatch null to clear the selected payment term
                              });
                            } else {
                              // Handle the case when a value is selected
                              const selectedTerm = paymentTerms?.find(
                                (item) => item.id === value
                              );
                              setSelectedPaymentTerms(value);
                              dispatch({
                                type: "PLACE_ORDER_DATA",
                                num: {
                                  ...PlaceOrderData,
                                  invpaymenttermId: value,
                                  invpaymenttermLabel: option
                                    ? option.label
                                    : "", // Handle option when cleared
                                },
                              });
                              dispatch({
                                type: "SELECTED_PAYMENT_TERM",
                                num: selectedTerm,
                              });
                            }
                          }}
                        >
                          {options?.map(renderOption)}
                        </Select>

                        {/* <Select
                          id="delivery-method"
                          placeholder="Select Payment Terms"
                          options={paymentTerms}
                          className="w-full wow"
                          onChange={(value) => {
                            {
                              setSelectedPaymentTerms(value);
                              dispatch({
                                type: "PLACE_ORDER_DATA",
                                num: {
                                  ...PlaceOrderData,
                                  invpaymenttermId: value,
                                },
                              });
                            }
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              backgroundColor: "#f0f0f0",
                              border: "none",
                            }),
                          }}
                        /> */}
                      </div>
                    </div>
                    <div className="flex gap-1 items-center justify-between text-[0.9rem] text-gray-600">
                      <h1 className="font-semibold text-[1rem]">
                        Payment Method
                      </h1>
                      <div className="flex gap-2 w-[70%]  items-center justify-center">
                        <Select
                          id="delivery-method"
                          placeholder="Select Payment Method"
                          options={methods}
                          className="w-full wow"
                          onChange={(value, option) => {
                            // setSelectedPaymentTerms(value);
                            dispatch({
                              type: "PLACE_ORDER_DATA",
                              num: {
                                ...PlaceOrderData,
                                paymentMethod: value,
                                paymentMethodLabel: option ? option.label : "", // Handle option when cleared
                              },
                            });
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              backgroundColor: "#f0f0f0",
                              border: "none",
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="w-full bg-white py-6 px-4 rounded-lg">
                  <h1 className="font-semibold text-[1rem]">Order Summary</h1>
                  {localCart.length > 0 && (
                    <div className="mx-auto w-full flex flex-col gap-2  mt-3 lg:max-w-2xl xl:max-w-4xl">
                      <div className="space-y-6">
                        {localCart?.map((item) => (
                          <CartItem
                            item={item}
                            onIncrement={handleIncrement}
                            onDecrement={handleDecrement}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          )}

          {localCart.length > 0 && (
            <div className="mt-8 flex gap-5">
              <Button
                // onClick={() => router("/Inventory-PlaceOrder")}
                className="bg-red-600 rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white w-[12rem]"
              >
                Cancel
              </Button>
              <Button
                // onClick={() => router("/Inventory-PlaceOrder")}
                onClick={() => {
                  if (user === "employee" && role !== "Permanent Secretary") {
                    GetPurchaseLimit();
                    return;
                  }
                  setShowVerify(true);

                  // placeOrder();
                }}
                className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white w-[12rem]"
              >
                Place Order
              </Button>
            </div>
          )}

          {localCart.length === 0 && (
            <div className="mt-8">
              <img src={cartAni} className="w-[30rem] h-64 rounded-md" />
              <h1 className="text-2xl font-bold text-center mt-4 text-gray-600">
                Cart Is Empty ....
              </h1>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${
          showDone ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[22rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowDone(false)}
            src={ConfirmOrder}
            alt=""
            className="w-20 h-20"
          />
          <p className="font-bold text-lg mt-[1.5rem] text-primary">
            Order sent
          </p>
          <p className="text-center mt-5 mb-5">
            Your quotation request has been successfully submitted.
          </p>
          <button
            onClick={() => {
              setShowDone(false);
              router("/Inventory-Vendor-Orders");
            }}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
      {showVerify && (
        <VerifyOrder
          setShow={setShowVerify}
          setShowErrorModal={setShowErrorModal}
          setErr={setErr}
          submit={placeOrder}
          display={display}
          setDisplay={setDisplay}
          setSignature={setSignature}
          signature={signature}
          exceedPurchaseLimit={exceedPurchaseLimit}
        />
      )}
    </div>
  );
};

export default EventDetails;
