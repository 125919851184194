import { useEffect, useRef, useState } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DatePicker, Select } from "antd";
import moment from "moment/moment";
const Mid = ({
  setRefetch,
  refetch,
  setShowSuccessModal,
  setErr,
  setShowErrorModal,
}) => {
  const router = useNavigate();
  const { Option } = Select;

  const token = useSelector((state) => state.token);

  const [type, setType] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  const [programs, setPrograms] = useState([]);
  const [method, setMethod] = useState(""); // Stores the selected method
  const [note, setNote] = useState(""); // Stores the note
  const [downPayPercent, setDownPayPercent] = useState(""); // For down-payment
  const [netDays, setNetDays] = useState(null); // For net-payment-terms
  const [validFrom, setValidFrom] = useState(null); // Start of validity
  const [validTo, setValidTo] = useState(null); // End of validity
  const [buyerProgram, setBuyerProgram] = useState(null); // Buyer-specific program

  const nextHandler = () => {
    // setShow(false);
    var body;
    body = {
      method: method,
      note: note,
      type: selectedOption === "Buyer Specific" ? "buyer-specific" : "default",
    };

    if (validFrom !== null && validFrom !== "") {
      body.validFrom = validFrom;
    }

    if (validTo !== null && validTo !== "") {
      body.validTo = validTo;
    }

    if (selectedOption === "Buyer Specific") {
      body.buyerProgram = buyerProgram;
    }

    if (method === "down-payment") {
      body.downPayPercent = downPayPercent;
      body.netDays = netDays;
    }

    if (method === "net-payment-terms") {
      body.netDays = netDays;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/inv-payment-terms`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        // setShow(false);
        setShowSuccessModal(true);
        setErr("Payment Term Added Successfully");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const options = programs?.map((item) => ({
    value: item?.name,
    label: item?.name,
    imageUrl: item?.icon,
  }));

  const formatProgramNames = (data) => {
    if (data === "plwd") return "PLWD";
    else if (data === "pa-medical") return "PA Medical";
    else if (data === "pa-economical") return "PA Economical";
    else
      return data
        .replace(/-/g, " ") // Replace hyphens with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex items-center">
        <img
          src={option?.imageUrl}
          alt={option?.label}
          style={{ width: 24, marginRight: 8 }}
        />
        <span>
          {formatProgramNames(
            option?.label === "plwd"
              ? "PLWD"
              : option?.label === "pa-medical"
              ? "PA Medical"
              : option?.label === "pa-economical"
              ? "PA Economical"
              : option?.label
          )}
        </span>
      </div>
    </Option>
  );

  const PaymentType = [
    {
      label: "Default Payment Terms",
      value: "Default Payment Terms",
    },
    {
      label: "Buyer Specific",
      value: "Buyer Specific",
    },
  ];

  const methods = [
    {
      value: "cash-on-delivery",
      label: "Cash on Delivery",
    },
    {
      value: "down-payment",
      label: "Down Payment",
    },
    {
      value: "net-payment-terms",
      label: "Net Payment Terms",
    },
  ];

  return (
    <div className="rounded-md mb-8  sm:min-h-[360px]  shadow-lg bg-white p-4 mt-7 ">
      <div className="flex justify-center">
        <div className="w-[90%] mt-3">
          <div className="mt-2">
            <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
              Payment Term
            </h2>
            <div className="w-full bg-[#F5F5FF] rounded-md py-4 px-3">
              <div className="flex gap-6  items-center">
                {PaymentType?.map((item, index) => (
                  <div className="flex items-center justify-center ">
                    <input
                      type="radio"
                      value={item?.value}
                      className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                      checked={item?.value === selectedOption}
                      onChange={(e) => {
                        setSelectedOption(item?.value);
                      }}
                    />
                    <label
                      htmlFor="gidOption"
                      className="cursor-pointer text-sm inline-flex items-center ml-2"
                    >
                      {item?.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className={`${
              selectedOption === "Buyer Specific" ? "mt-2" : "hidden"
            }`}
          >
            <h2 className="text-gray-600 text-[0.9rem] font-semibold">
              Program
            </h2>

            <Select
              placeholder="Select Program"
              // className="wow w-[100%] mb-4"
              className="w-[100%] woww mb-4"
              value={buyerProgram}
              renderOption={renderOption}
              onChange={
                (value) => {
                  setBuyerProgram(value);
                }
                // console.log(value)
              }
            >
              {options?.map(renderOption)}
            </Select>
          </div>

          <div className="mt-2">
            <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
              Payment Method
            </h2>
            <div className="w-full gap-3 bg-[#F5F5FF] rounded-md py-4 px-3">
              <div className="flex gap-6  items-center">
                {methods?.map((item, index) => (
                  <div className=" flex items-center">
                    <input
                      type="radio"
                      id="gidOption"
                      name="contactMethod"
                      value="tier"
                      className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                      checked={method === item?.value}
                      onChange={(e) => {
                        setMethod(item?.value);
                      }}
                    />
                    <label
                      htmlFor="gidOption"
                      className="cursor-pointer text-sm inline-flex items-center ml-2"
                    >
                      {item?.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-[60%] mt-2">
            <div className={`${method === "down-payment" ? "mt-2" : "hidden"}`}>
              <h2 className="text-gray-600 text-[0.9rem] font-semibold">
                Down Payment %
              </h2>

              <input
                type="text"
                placeholder={"Specify Percentage"}
                value={downPayPercent ? `${downPayPercent}` : ""}
                onChange={(e) => {
                  setDownPayPercent(e.target.value);
                }}
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
              />
            </div>

            <div
              className={`${
                method === "net-payment-terms" || method === "down-payment" ? "mt-2" : "hidden"
              }`}
            >
              <h2 className="text-gray-600 text-[0.9rem] font-semibold">
                Credit Duration
              </h2>

              <Select
                placeholder="Credit Duration"
                optionFilterProp="children"
                className="wow"
                value={netDays}
                onChange={(val) => setNetDays(val)}
                size="middle"
                options={[
                  { value: 10, label: "Net 10" },
                  { value: 15, label: "Net 15" },
                  { value: 30, label: "Net 30" },
                  { value: 45, label: "Net 45" },
                  { value: 60, label: "Net 60" },
                  { value: 90, label: "Net 90" },
                ]}
              />
            </div>

            <div className={`${selectedOption === "tier" ? "mt-2" : "hidden"}`}>
              <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                Type:
              </h2>

              <Select
                placeholder="Select Type"
                optionFilterProp="children"
                className="wow"
                value={type}
                onChange={(val) => setType(val)}
                size="middle"
                options={[
                  { value: "weight", label: "Weight" },
                  { value: "quantity", label: "Quantity" },
                ]}
              />
            </div>

            <div
              className={`${
                selectedOption === "tier"
                  ? "flex  gap-2 mt-2"
                  : "flex  gap-2 mt-2"
              }`}
            >
              <div className="flex-1">
                <label className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                  Valid From
                </label>
                <DatePicker
                  placeholder="Valid From"
                  // value={wow !== "" ? set : null}
                  onChange={(e, f) => setValidFrom(f)}
                  disabledDate={(current) => current && current < moment().startOf('day')}
                />
              </div>
              <div className="flex-1">
                <label className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                  Valid To
                </label>
                <DatePicker
                  placeholder="Valid To"
                  // value={wow !== "" ? set : null}
                  onChange={(e, f) => setValidTo(f)}
                  disabledDate={(current) => current && current < moment().startOf('day')}
                />
              </div>
            </div>

            <div className="mt-2">
              <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                Note
              </h2>

              <textarea
                placeholder="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
            </div>
          </div>

          <div className="flex items-center justify-center pb-6 mt-4">
            <button
              onClick={() => {
                nextHandler();
                // setShow(false);
                // setShowSuccessModal(true);
              }}
              // onClick={nextHandler}
              className="ml-5 cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mid;
