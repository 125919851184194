import React, { useState, useEffect } from "react";
import { Button, Rate, Tabs, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import like from "../../../imgs/heartF.png";
import dislike from "../../../imgs/heartD.png";
import share from "../../../imgs/share.png";
import cartW from "../../../imgs/cartW.png";
// import exchange from "../../../imgs/exchange.svg";
import exchange from "../../../imgs/activeExchange.svg";
import returnImg from "../../../imgs/activeReturn.svg";
import activeDelivery from "../../../imgs/activeDelivery.svg";
// import returnImg from "../../../imgs/return.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const { TabPane } = Tabs;
const EventDetails = ({ productData }) => {
  const SelectedVendors = useSelector((state) => state.SelectedVendors);
  const [data, setData] = useState(productData);
  console.log(data);

  const SelectedCompanyVendor = useSelector(
    (state) => state.SelectedCompanyVendor
  );

  const SelectedBranchVendor = useSelector(
    (state) => state.SelectedBranchVendor
  );

  const name = SelectedBranchVendor
    ? SelectedBranchVendor?.name
    : SelectedCompanyVendor?.name;
  const selectedProgramBuyer = useSelector((state) => state.selectedProgram);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const router = useNavigate();
  const cart = useSelector((state) => state.cartData);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const [localCart, setCart] = useState(cart);
  const [mainImage, setMainImage] = useState(
    data?.invproductgallaries?.[0]?.image
  );
  const fav = useSelector((state) => state.fav);
  const [favorite, setFavorite] = useState(fav);
  const [flag, setFlag] = useState("n");
  const [initial, setinitial] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialFav, setinitialFav] = useState(true);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const thumbnailsToShow = 3;
  const [discount, setDiscount] = useState(null);
  const [afterApi, setAfterApi] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setAfterApi(false);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/inv-order/calc-product-items-discounts`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              invProductId: data?.id,
              units: 1,
              program: selectedProgramBuyer,
            },
          }
        );
        setDiscount(response?.data);
        setAfterApi(true);
      } catch (error) {
        console.error(error);
        setAfterApi(true);
      }
    };

    fetchData();
  }, []);

  //get Product Detail
  // Function to fetch the unit price for a single product
  const fetchUnitPrice = async (invProductId, quantity, token) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-order/overall-prod-items-calc-by-buyer`,
        {
          params: {
            invProductId,
            units: quantity,
            program: selectedProgramBuyer,
          },
          headers,
        }
      );

      // console.log("response", response);

      // Extract price and type from the response
      const price = response?.data?.invAdvancePrice?.price || 0; // Replace with the actual key if different
      const type = response?.data?.invAdvancePrice?.type || ""; // Replace with the actual key if different
      const taxes = response?.data?.taxDetails?.totalTax || 0;
      const discounts = response?.data?.discountDetails?.totalDiscount || 0;
      const delivery = response?.data?.deliveryFee?.totalCalcDeliveryFee || 0;

      return { price, type, taxes, discounts, delivery }; // Replace with the actual response key
    } catch (error) {
      console.error("Error fetching unit price:", invProductId, error);
      return { price: 0, type: "" }; // Return default values if there's an error
    }
  };

  // console.log(discount);

  const handlePrev = () => {
    setThumbnailIndex((prevIndex) =>
      prevIndex > 0
        ? prevIndex - 1
        : data?.invproductgallaries?.length - thumbnailsToShow
    );
  };

  const handleNext = () => {
    setThumbnailIndex((prevIndex) =>
      prevIndex < data?.invproductgallaries?.length - thumbnailsToShow
        ? prevIndex + 1
        : 0
    );
  };

  const ratingsData = {
    0: [{ name: "John Doe", comment: "No stars given." }],
    1: [{ name: "Jane Smith", comment: "Poor quality." }],
    2: [{ name: "Alice Johnson", comment: "Not great, but okay." }],
    3: [{ name: "Chris Lee", comment: "Average experience." }],
    4: [{ name: "Emily Davis", comment: "Good, but not perfect." }],
    5: [{ name: "Michael Brown", comment: "Excellent! Highly recommended." }],
  };

  const productDetails = [
    {
      label: "Minimum order Quantity:",
      value: data?.minimumOrderQty ?? 1,
    },
    { label: "Item Code:", value: data?.itemCode ?? "---" },
    { label: "SKU", value: data?.sku ?? "----" },
    { label: "Category:", value: data?.invcategories?.[0]?.name ?? "---" },
    {
      label: "Warranty:",
      value:
        data?.warranty && Number(data?.warranty) > 1
          ? `${data?.warranty} days`
          : data?.warranty ?? "---",
    },
    { label: "Vendor:", value: name },
  ];

  // const displayedThumbnails = data?.invproductgallaries?.images?.slice(
  //   thumbnailIndex,
  //   thumbnailIndex + thumbnailsToShow
  // );
  const displayedThumbnails = data?.invproductgallaries
    ?.slice(thumbnailIndex, thumbnailIndex + thumbnailsToShow)
    .map((gallery) => gallery?.image);

  // console.log("display", displayedThumbnails);

  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    setFavorite(fav);
  }, [fav]);
  useEffect(() => {
    if (flag === "buy") {
      router("/Inventory-Cart");
      setFlag("sd");
    }
  }, [flag]);
  useEffect(() => {
    // console.log("OkKK", localCart);
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);
  useEffect(() => {
    // console.log("fav", favorite);
    if (!initialFav) {
      dispatch({ type: "Fav", num: favorite });
    }
  }, [favorite]);

  const onIncrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const onDecrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  // const increaseQuantity = (data) => {
  //   setinitial(false);

  //   // Check if the item is already in the cart
  //   const itemExists = localCart.some((item) => item.id === data?.id);

  //   if (itemExists) {
  //     // If the item exists, increment the quantity
  //     const updatedCart = localCart.map((item) =>
  //       item.id === data?.id ? { ...item, quantity: item.quantity + 1 } : item
  //     );
  //     setCart(updatedCart);
  //   } else {
  //     // If the item doesn't exist, call addToCart to add it
  //     addToCart(data);
  //   }
  // };

  // const decreaseQuantity = (data) => {
  //   setinitial(false);

  //   const updatedCart = localCart
  //     .map((item) => {
  //       if (item.id === data?.id) {
  //         if (item.quantity > 1) {
  //           // Decrease the quantity if it's greater than 1
  //           return { ...item, quantity: item.quantity - 1 };
  //         }
  //         // Call the removeCart function for quantity 1
  //         removeFromCart(item.id);
  //         return null; // Mark for removal
  //       }
  //       return item;
  //     })
  //     .filter(Boolean); // Remove null items

  //   setCart(updatedCart);
  // };

  // const addToCart = (event) => {
  //   setinitial(false);
  //   const cartItem = {
  //     id: event.id,
  //     image: event.invproductgallaries?.[0]?.image,
  //     name: event.name,
  //     price: event.basePrice,
  //     amount: event.amount,
  //   };
  //   const updatedCart = [...localCart, cartItem];
  //   setCart(updatedCart);
  // };

  const increaseQuantity = (res) => {
    setinitial(false);
    if (isAddedToCart(res?.id)) {
      const updatedCart = localCart.map((item) => {
        if (item.id === res?.id) {
          // Increment the quantity of the existing item
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });

      setData((prevData) => ({
        ...prevData,
        addedQuantity: prevData.addedQuantity + 1,
      }));

      setCart(updatedCart);
    } else {
      setData((prevData) => ({
        ...prevData,
        addedQuantity: prevData.addedQuantity + 1,
      }));
    }
  };

  const decreaseQuantity = (res) => {
    setinitial(false);
    if (isAddedToCart(res?.id)) {
      const updatedCart = localCart
        .map((item) => {
          if (item.id === res?.id) {
            if (item.quantity > 1) {
              // Decrease the quantity if it's greater than 1
              return { ...item, quantity: item.quantity - 1 };
            }
            // Call the removeCart function for quantity 1
            removeFromCart(item.id);
            return null; // Mark for removal
          }
          return item;
        })
        .filter(Boolean); // Remove null items

      setData((prevData) => ({
        ...prevData,
        addedQuantity: prevData.addedQuantity - 1,
      }));

      setCart(updatedCart);
    } else {
      setData((prevData) => ({
        ...prevData,
        addedQuantity: prevData.addedQuantity - 1,
      }));
    }
  };

  const addToCart = (data, type) => {
    // console.log("event", data);
    setinitial(false);

    const cartItem = {
      id: data?.id,
      image: data?.invproductgallaries?.find((gallery) => gallery?.isCoverImg)
        ?.image
        ? data?.invproductgallaries?.find((gallery) => gallery?.isCoverImg)
            ?.image
        : "https://t3.ftcdn.net/jpg/03/45/05/92/360_F_345059232_CPieT8RIWOUk4JqBkkWkIETYAkmz2b75.jpg",
      name: data?.name,
      // quantity: type === "buyNow" ? data?.minimumOrderQty : 1,
      quantity: data?.addedQuantity !== null ? data?.addedQuantity : 1,
      price: parseFloat(data?.basePrice), // Convert to number
      unit: data?.pricingUnit,
      minOrder: data?.minimumOrderQty,
      itemCode: data?.itemCode,
    };

    const existingItemIndex = localCart?.findIndex(
      (item) => item.id === data?.id
    );

    let updatedCart;

    if (existingItemIndex !== -1) {
      // Item already exists, increment the quantity
      updatedCart = localCart?.map((item, index) =>
        index === existingItemIndex
          ? { ...item, quantity: item?.quantity + 1 }
          : item
      );
    } else {
      // Item does not exist, add it to the cart
      updatedCart = [...localCart, cartItem];
    }

    setCart(updatedCart);
  };

  const addToFav = (event) => {
    setinitialFav(false);
    const favItems = {
      id: event.id,
      coverPhotos: event.coverPhotos,
      name: event.name,
      quantity: event.quantity,
      price: event.price,
      amount: event.amount,
    };

    const updatedFav = [...favorite, favItems];
    setFavorite(updatedFav);
  };

  const removeFromFav = (eventId) => {
    setinitialFav(false);
    const updatedFav = favorite.filter((item) => item.id !== eventId);
    setFavorite(updatedFav);
  };

  const isAddedToFav = (eventId) => {
    return favorite.some((item) => item.id === eventId);
  };

  const removeFromCart = (eventId) => {
    setinitial(false);
    const updatedCart = localCart.filter((item) => item.id !== eventId);
    setCart(updatedCart);
  };

  const isAddedToCart = (eventId) => {
    return localCart.some((item) => item.id === eventId);
  };

  function ProductInfo() {
    return (
      <div className="flex flex-col space-y-2">
        {productDetails.map((detail, index) => (
          <div key={index} className="flex justify-between">
            <span className="font-normal text-[0.93rem] text-gray-700">
              {detail.label}
            </span>
            <span className="font-semibold text-[0.93rem] text-gray-900">
              {detail.value}
            </span>
          </div>
        ))}
      </div>
    );
  }

  // const CustomPrevArrow = (props) => {
  //   const { onClick } = props;
  //   return (
  //     <Button
  //       className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  left-0 transform -translate-y-1/2"
  //       onClick={onClick}
  //     >
  //       <LeftOutlined className="text-white font-semibold" />
  //     </Button>
  //   );
  // };

  // const CustomNextArrow = (props) => {
  //   const { onClick } = props;
  //   return (
  //     <Button
  //       className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  right-0 transform -translate-y-1/2"
  //       onClick={onClick}
  //     >
  //       <RightOutlined className="text-white font-semibold" />
  //     </Button>
  //   );
  // };

  // const getDisplayedEvents = (currentIndex, count) => {
  //   const end = currentIndex + count;
  //   return events
  //     .slice(currentIndex, end)
  //     .concat(events.slice(0, Math.max(0, end - events.length)));
  // };

  // const displayedR = getDisplayedEvents(currentIndex, 4);
  return (
    <div className="flex w-full pb-[5rem] pt-[1rem] items-center justify-center">
      <div className="w-full  flex flex-col">
        <div className="w-full h-full h-auto grid grid-cols-[45%_55%]">
          <div key={data.id} className="w-full flex flex-col gap-2 mt-2">
            <div className="w-full h-[300px] mb-2">
              <img
                className="w-full h-[300px] rounded-[1.2rem]"
                src={mainImage}
                alt={data?.name}
              />
            </div>
            <div className="relative w-full flex items-center">
              <button
                onClick={handlePrev}
                className="absolute left-0 z-10 p-2 rounded-full"
              >
                <LeftOutlined />
              </button>
              {/* w-full flex gap-2 justify-between items-center overflow-hidden mx-8 */}
              <div className="w-full grid grid-cols-3 gap-2 mx-8">
                {displayedThumbnails?.map((coverPhoto, index) => (
                  <div
                    key={index}
                    className="w-full h-[100px] border  rounded-[0.8rem] cursor-pointer"
                    onMouseEnter={() => setMainImage(coverPhoto)}
                  >
                    <img
                      className="w-full h-full rounded-[0.8rem]"
                      src={coverPhoto}
                      alt={`${data.name}-${index}`}
                    />
                  </div>
                ))}
              </div>
              <button
                onClick={handleNext}
                className="absolute right-0 z-10 p-2 rounded-full"
              >
                <RightOutlined />
              </button>
            </div>

            {/* Exchange Return */}
            <div class="flex items-center justify-center  bg-gray-100 mt-7">
              <div class="grid grid-cols-3 gap-10 px-8">
                {data?.freeDeliveryCondition !== null && (
                  <div class="flex flex-col items-center">
                    <div class="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
                      <img
                        src={activeDelivery}
                        alt="No Return Icon"
                        class="w-8 h-8"
                      />
                    </div>
                    <p class="mt-2 text-sm font-semibold text-gray-800">
                      Free Delivery
                    </p>
                    <p class="mt-1 text-[13px] font-semibold text-gray-600 text-center">
                      {data?.freeDeliveryCondition === "by-units"
                        ? `for Minimum quantity ${data?.freeDeliveryConditionValue} units`
                        : `for a minimum purchase of $${data?.freeDeliveryConditionValue} `}
                    </p>
                  </div>
                )}

                <div class="flex flex-col items-center">
                  <div class="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
                    <img src={exchange} alt="No Return Icon" class="w-8 h-8" />
                  </div>
                  {data?.exchangable ? (
                    <p class="mt-2 text-sm font-semibold text-gray-800">
                      Exchangable within
                    </p>
                  ) : (
                    <p class="mt-2 text-sm font-semibold text-gray-800">
                      No Exhange
                    </p>
                  )}
                  <p class="mt-1 text-sm font-semibold text-gray-800">
                    {data?.exchangable ? `${data?.exchangableDays} Days` : ""}
                  </p>
                </div>

                <div class="flex flex-col items-center">
                  <div class="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
                    <img src={returnImg} alt="No Return Icon" class="w-8 h-8" />
                  </div>
                  {data?.returnable ? (
                    <p class="mt-2 text-sm font-semibold text-gray-800">
                      Returnable within
                    </p>
                  ) : (
                    <p class="mt-2 text-sm font-semibold text-gray-800">
                      No Return
                    </p>
                  )}
                  <p class="mt-1 text-sm font-semibold text-gray-800">
                    {data?.returnable ? `${data?.returnableDays} Days` : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col border-b  px-4">
            <div className="w-full flex flex-col pb-4 border-b">
              <div className="flex gap-4 items-center">
                <h1 className="text-3xl font-semibold">{data.name}</h1>
                {/* <div>
                  <button
                    className="text-white bg-[#256091] pl-2 pr-2 py-2  rounded-lg sm:text-md text-md w-[11rem] flex items-center justify-center space-x-2"
                    onClick={() => addToCart(data)}
                  >
                    <span>Add to Cart</span>
                    <img src={cartW} alt="Cart Icon" className="w-5 h-5" />
                  </button>
                </div> */}

                {/* <p className="font-semibold mt-3 text-gray-700">
                ${data.price}/{data.amount}
              </p> */}
              </div>
              <div className="flex items-center w-full justify-between">
                <div className="mt-2 items-center flex gap-4">
                  <Rate allowHalf defaultValue={4.5} />
                  <h1 className="font-semibold text-primary mt-2">
                    14 Ratings
                  </h1>
                </div>
                {/* <div className="mt-2 items-center flex gap-4">
                  <img
                    onClick={() => removeFromFav(data.id)}
                    src={share}
                    alt=""
                    className="w-5 h-5 cursor-pointer"
                  />
                  {isAddedToFav(data.id) ? (
                    <img
                      onClick={() => removeFromFav(data.id)}
                      src={like}
                      alt=""
                      className="w-5 h-5 cursor-pointer"
                    />
                  ) : (
                    <img
                      onClick={() => addToFav(data)}
                      src={dislike}
                      alt=""
                      className="w-5 h-5 cursor-pointer"
                    />
                  )}
                </div> */}
              </div>
              {/* <div>
                <button
                  onClick={() => {
                    router("/Inventory-Vendor-Products");
                  }}
                  className="mt-2 pl-3 pr-3 p-1 bg-gray-200 rounded-full font-semibold hover:bg-gray-300"
                >
                  Semule's Grocery Store
                </button>
              </div> */}
            </div>

            <div className="flex flex-col gap-2 pb-4 ">
              <div className="bg-white  rounded-sm flex items-center  justify-between relative px-3">
                {/* <div className="">
                  <div className="flex gap-10">
                    <h1 className="font-semibold text-xl text-red-600">
                      Price :
                    </h1>
                    <p className="font-semibold text-xl text-red-600">
                      ${data.basePrice}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <h1 className="text-md text-gray-600 line-through">
                      Price : 100
                    </h1>
                    <p className="">-20%</p>
                  </div>
                </div> */}

                {/* <div className="">
                  {data.discount === 0 ? (
                    // When there is no discount
                    <div className="flex gap-10">
                      <h1 className="font-semibold text-xl text-red-600">
                        Price :
                      </h1>
                      <p className="font-semibold text-xl text-red-600">
                        ${data?.basePrice}
                      </p>
                    </div>
                  ) : (
                    // When there is a discount
                    <>
                      <div className="flex gap-10">
                        <h1 className="text-md text-gray-600 line-through">
                          Price: ${data?.basePrice}
                        </h1>
                        <p className="font-semibold text-xl text-red-600">
                          ${data?.basePrice - data?.discount}
                        </p>
                      </div>
                      <p className="text-gray-500 text-sm">
                        Offer valid till {data.discountEndDate || "30th Nov"}
                      </p>
                    </>
                  )}
                </div> */}
                <div className={`${afterApi ? "" : "hidden"}`}>
                  {discount?.productDiscounts?.[0] ? (
                    // When there is a discount
                    <>
                      <div className="flex gap-10">
                        <h1 className="text-md text-gray-600 line-through">
                          Price: ${data?.basePrice}
                        </h1>
                        <p className="font-semibold text-xl text-red-600">
                          $
                          {(
                            data?.basePrice -
                            (discount?.productDiscounts?.[0]?.type === "percent"
                              ? (data?.basePrice *
                                  discount?.productDiscounts?.[0]?.value) /
                                100
                              : discount?.productDiscounts?.[0]?.value || 0)
                          ).toFixed(2)}
                        </p>
                      </div>
                      <p className="text-gray-500 text-sm">
                        {/* {data?.description || "Offer valid till 30th Nov"} */}
                      </p>
                    </>
                  ) : (
                    // When there is no discount
                    <div className="flex gap-10">
                      <h1 className="font-semibold text-xl text-red-600">
                        Price :
                      </h1>
                      <p className="font-semibold text-xl text-red-600">
                        ${data?.basePrice}
                      </p>
                    </div>
                  )}
                </div>

                {data?.barCode && (
                  <img
                    src={data?.barCode ? data?.barCode : ""}
                    alt=""
                    className="w-[10rem] h-[5rem]  p-3"
                  />
                )}
              </div>
              <div className="mt-4 flex gap-4 items-center mb-3">
                <h2 className="font-normal text-[0.93rem]">Quantity</h2>
                {/* <div className="flex items-center mt-2 gap-4">
                  <Button
                    onClick={() => {
                      decreaseQuantity(data);
                    }}
                    className="bg-gray-300 text-black px-2 w-8 rounded-md"
                  >
                    -
                  </Button>
                  <span className="mx-2">
                    {" "}
                    {localCart.find((item) => item.id === data?.id)?.quantity ||
                      0}{" "}
                  </span>
                  <Button
                    onClick={() => {
                      increaseQuantity(data);
                    }}
                    className="bg-gray-300 text-black px-2 w-8 rounded-md"
                  >
                    +
                  </Button>
                </div> */}
                <button className="rounded-md cursor-pointer pl-5 pr-5 text-semibold text-white p-1">
                  <div className="flex items-center justify-center gap-2">
                    <div
                      onClick={() => {
                        if (isAddedToCart(data?.id)) {
                          if (
                            localCart.find((item) => item.id === data?.id)
                              ?.quantity <= data?.minimumOrderQty
                          ) {
                            return;
                          }
                          decreaseQuantity(data);
                        } else {
                          if (data?.addedQuantity <= data?.minimumOrderQty) {
                            return;
                          }
                          setData((prevData) => ({
                            ...prevData,
                            addedQuantity: data?.addedQuantity - 1,
                          }));
                          // setData((prevData) =>
                          //   prevData.map((product) =>
                          //     product.id === data?.id
                          //       ? {
                          //           ...product,
                          //           addedQuantity: product?.addedQuantity - 1,
                          //         }
                          //       : product
                          //   )
                          // );
                        }
                      }}
                      className="bg-white border border-primary rounded-md cursor-pointer pl-2 pr-2 text-bold text-gray-800 p-1"
                    >
                      -
                    </div>

                    <input
                      className="bg-primary rounded-md cursor-pointer pl-3 pr-3 text-semibold text-white p-1 w-14 text-center"
                      value={
                        isAddedToCart(data?.id)
                          ? localCart.find((item) => item.id === data?.id)
                              ?.quantity
                          : data?.addedQuantity !== null
                          ? data?.addedQuantity
                          : 1
                        // localCart.find((item) => item.id === data?.id)
                        //   ?.quantity || data?.minimumOrderQty
                      }
                      onChange={(e) => {
                        let inputQuantity = parseInt(e.target.value);

                        if (isNaN(inputQuantity)) {
                          inputQuantity = "";
                        }

                        // if (isAddedToCart(data?.id)) {
                        //   setCart((prevCart) =>
                        //     prevCart.map((item) =>
                        //       item.id === data?.id
                        //         ? {
                        //             ...item,
                        //             quantity: inputQuantity,
                        //           }
                        //         : item
                        //     )
                        //   );
                        // }
                        if (isAddedToCart(data?.id)) {
                          setCart((prevCart) =>
                            prevCart.map((item) =>
                              item.id === data?.id
                                ? {
                                    ...item,
                                    quantity: inputQuantity,
                                  }
                                : item
                            )
                          );
                        } else {
                          setCart((prevCart) => [
                            ...prevCart,
                            {
                              ...data,
                              quantity: inputQuantity,
                            },
                          ]);
                        }

                        setData((prevData) => ({
                          ...prevData,
                          addedQuantity: inputQuantity,
                        }));
                      }}
                      onBlur={() => {
                        const currentQuantity = data?.addedQuantity;

                        const minimumQuantity = data?.minimumOrderQty || 1;

                        if (currentQuantity < minimumQuantity) {
                          setData((prevData) => ({
                            ...prevData,
                            addedQuantity: minimumQuantity,
                          }));
                        }
                      }}
                    />

                    <div
                      onClick={() => {
                        if (isAddedToCart(data?.id)) {
                          increaseQuantity(data);
                        } else {
                          setData((prevData) => ({
                            ...prevData,
                            addedQuantity: data?.addedQuantity + 1,
                          }));
                          // setData((prevData) =>
                          //   prevData.map((product) =>
                          //     product.id === event?.id
                          //       ? {
                          //           ...product,
                          //           addedQuantity: product?.addedQuantity + 1,
                          //         }
                          //       : product
                          //   )
                          // );
                        }
                      }}
                      className="bg-white border border-primary rounded-md cursor-pointer pl-2 pr-2 text-bold text-gray-800 p-1"
                    >
                      +
                    </div>
                  </div>
                </button>
                <div className="mt-1">
                  <button
                    className="text-white bg-[#256091] pl-2 pr-2 py-2  rounded-lg sm:text-md text-md w-[11rem] flex items-center justify-center space-x-2"
                    onClick={() => addToCart(data)}
                  >
                    <span>Add to Cart</span>
                    <img src={cartW} alt="Cart Icon" className="w-5 h-5" />
                  </button>
                </div>
              </div>

              <div className="w-[60%]">
                <ProductInfo />
              </div>
              <div className="w-full flex gap-4">
                <div className="mt-6 w-full">
                  <h1 className="font-semibold text-gray-800 text-xl">
                    Description
                  </h1>
                  <p className="mb-10 mt-2">
                    {data?.description ?? "No Description Available"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center h-full justify-center">
              <div className="w-[80%] p-6 flex flex-col items-center justify-center rounded-xl mt-6">
                <div className="flex w-full  gap-3">
                  {/* {isAddedToCart(data.id) ? (
                    <button
                      className="text-red-600 w-full bg-gray-400 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md "
                      onClick={() => removeFromCart(data.id)}
                    >
                      Remove from Cart
                    </button>
                  ) : ( */}
                  <button
                    className="text-white w-full bg-[#FF9F40] pl-5 pr-5 py-2 rounded-lg sm:text-[1rem] text-md "
                    onClick={async () => {
                      if (SelectedCompanyVendor) {
                        const isAlreadySelected = SelectedVendors?.some(
                          (vendor) =>
                            vendor?.vendorcompany?.id ===
                            SelectedCompanyVendor?.id
                        );

                        if (!isAlreadySelected) {
                          await addToCart(data);

                          dispatch({
                            type: "SELECTED_VENDORS",
                            num: [
                              ...SelectedVendors,
                              {
                                vendorcompany: SelectedCompanyVendor,
                                products: [
                                  {
                                    productId: data.id,
                                    itemCode: data?.itemCode,
                                  },
                                ],
                                // products: localCart?.map((item) => ({
                                //   productId: item.id,
                                //   itemCode: item?.itemCode,
                                // })),
                              },
                            ],
                          });
                        }
                      }

                      dispatch({ type: "QUOTATION_FLOW", num: true });
                      router("/Inventory/SendQuotation");
                    }}
                  >
                    Request Quotations
                  </button>
                  {/* )} */}
                  <button
                    onClick={async () => {
                      await addToCart(data, "buyNow");
                      router("/Inventory-Cart");
                      // router("/Inventory-Buy");
                      // addToCart(data);
                      // setFlag("buy")
                    }}
                    className={`text-white w-full bg-primary pl-5 pr-5 py-2 rounded-lg sm:text-[1rem] text-md `}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-full flex gap-4">
          <div className="mt-6 w-full">
            <h1 className="font-semibold text-gray-800 text-xl">Description</h1>
            <p className="mb-10 mt-2">
              {data?.description ?? "No Description Available"}
            </p>
          </div>
        </div> */}
        <div className="w-full flex flex-col gap-2 mt-2">
          <h1 className="font-semibold text-gray-800 text-xl">
            Product Ratings
          </h1>
          <div className="mt-2 items-center flex gap-4">
            <Rate allowHalf defaultValue={4.5} />
            <h1 className="font-semibold text-primary mt-2">14 Ratings</h1>
          </div>
          <Tabs defaultActiveKey="0" tabBarStyle={{ marginBottom: 0 }}>
            {Object.keys(ratingsData).map((key) => (
              <TabPane
                tab={
                  <span className="border p-2 ml-2  rounded-md bg-gray-200 hover:bg-gray-300">
                    <span className="text-semibold p-2">{key} Stars</span>
                  </span>
                }
                key={key}
              >
                {ratingsData[key].map((rating, index) => (
                  <div key={index} className="p-2">
                    <p className="font-bold">{rating.name}</p>
                    <p>{rating.comment}</p>
                  </div>
                ))}
              </TabPane>
            ))}
          </Tabs>
        </div>
        {/* <div className="mt-6">
          <h1 className="font-semibold text-gray-800 mb-4 text-xl">
            Recently Bought :
          </h1>
          <div className="relative w-full flex items-center">
            <button
              onClick={handlePrevR}
              className="absolute left-0 z-10 p-2 rounded-full"
            >
              <LeftOutlined />
            </button>
            <div className="w-full flex gap-1 justify-between items-center overflow-hidden mx-8">
              {displayedR?.map((event) => (
                <div key={event.id} className="w-[22%]">
                  <Carousel
                    arrows
                    prevArrow={<CustomPrevArrow />}
                    nextArrow={<CustomNextArrow />}
                  >
                    {data?.invproductgallaries?.map((coverPhoto, index) => (
                      <div key={index}>
                        <img
                          className="w-full h-36 rounded-tr-[1.2rem] rounded-tl-[1.2rem] object-center items-center object-cover"
                          src={coverPhoto?.image}
                          alt={event.name}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <div className="px-6 py-3 bg-gray-100 rounded-br-[1.2rem] rounded-bl-[1.2rem] relative">
                    <div
                      onClick={() => {
                        // dispatch({ type: "Product", num: event });
                        // router(role === "buyer" ? event?.route : event.routeV);
                      }}
                      className="font-semibold w-full flex flex-col items-center justify-center text-center text-xl hover:text-[#1BA397] cursor-pointer"
                    >
                      {data.name}
                    </div>
                    <div className="flex justify-center items-center">
                      <span className="mr-2 text-gray-500  text-center whitespace-nowrap font-semibold">
                        Old Price:
                      </span>
                      <p className="text-red-500 line-through text-center font-semibold">
                        ${event.basePrice + 4}/{event.pricingUnit}
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      <span className="mr-2 text-gray-500 text-center font-semibold">
                        New Price:
                      </span>
                      <p className="text-green-500 text-center font-semibold">
                        ${event.basePrice}/{event.pricingUnit}
                      </p>
                    </div>

                    {user === "superadmin" && (
                      <div className="w-full flex items-center mt-2 justify-center">
                        {isAddedToCart(event.id) ? (
                          <button
                            className="bg-red-600 rounded-xl cursor-pointer pl-5 pr-5 text-semibold text-white"
                            // onClick={() => removeFromCart(event.id)}
                          >
                            Remove from Cart
                          </button>
                        ) : (
                          <button
                            className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 text-semibold text-white"
                            // onClick={() => addToCart(event)}
                          >
                            Add to Cart
                          </button>
                        )}
                      </div>
                    )}
                    {user === "superadmin" &&
                      (isAddedToFav(event.id) ? (
                        <img
                          // onClick={() => removeFromFav(event.id)}
                          src={like}
                          alt=""
                          className="w-4 h-4 absolute cursor-pointer right-[5px] top-[5px]"
                        />
                      ) : (
                        <img
                          // onClick={() => addToFav(event)}
                          src={dislike}
                          alt=""
                          className="w-4 h-4 cursor-pointer absolute right-[5px] top-[5px]"
                        />
                      ))}
                    <div className="w-full flex items-center justify-center">
                      <button
                        onClick={() => {
                          "";
                        }}
                        className="mt-2 pl-3 pr-3 p-1 bg-gray-200 rounded-full font-semibold hover:bg-gray-300"
                      >
                        {event?.vendor}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={handleNextR}
              className="absolute right-0 z-10 p-2 rounded-full"
            >
              <RightOutlined />
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EventDetails;
