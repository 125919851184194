import React from "react";
import pencil from "../../../../imgs/pencil.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
const SpecificPriceTab = ({
  data,
  setShow,
  setErr,
  setRefetch,
  refetch,
  setShowSuccessModal,
  setShowErrorModal,
}) => {
  const token = useSelector((state) => state.token);
  const DeletePrice = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-product/delete-advance-price/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setErr(`Specific Price Deleted Successfully`);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };
  return (
    <div className="p-6">
      <div className="flex w-[90%] px-2">
        <h2 className="w-2/5">Pricing Name</h2>
        <h2 className="3/5">Pricing</h2>
      </div>
      <div className="grid grid-cols-1 gap-5 mt-2">
        {data?.map((item, index) => (
          <div className="p-4 py-3 rounded-lg bg-[#EEEDED] shadow relative w-[90%]">
            <div className="flex w-full">
              <h2 className="w-2/5 capitalize">{item?.specificGroup}</h2>
              <h2 className="w-3/5 font-bold">{item?.price}</h2>
              <div
                className="flex gap-3"
                onClick={() => {
                  //   setShowModal(true);
                }}
              >
                {/* <img src={pencil} alt="edit" /> */}
                <DeleteFilled
                  style={{
                    fontSize: "20px",
                    color: "#FF0000",
                   
                  }}
                  onClick={() => DeletePrice(item?.id)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecificPriceTab;
