import back5 from "../../../../imgs/back5.png";
import img from "../../../../imgs/impLogo.png";
import { useNavigate } from "react-router";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Nav from "../../vendor/navbar";
import Cards from "./cards";
import axios from "axios";

const AdminPanel = () => {
  const user = useSelector((state) => state.user);
  console.log(user);
  const dispatch = useDispatch();
  const router = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const token=useSelector((state)=>state.token)

  useEffect(() => {
    dispatch({ type: "PRODUCT_TAXES", num: [] });
    dispatch({ type: "PLACE_ORDER_CHECK", num: false });
    dispatch({ type: "Cart", num: [] });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (user !== "employee") {
      dispatch({ type: "Role", num: null });
      // return;
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/my-profile/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        dispatch({ type: "Role", num: data?.data?.employeeInfo?.role });
        dispatch({ type: "setProfileData", num: data?.data });
      })
      .catch(() => {
      });
  }, []);

  return (
    <div>
      <Nav />
      <div>
        {contextHolder}
        <div className={`flex items-center justify-center bg-[#D9EFED]`}>
          <div className="md:w-[95%] mt-[1rem] rounded-md w-[95%] sm:px-20 px-4">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  router("/superAdminDashboard");
                  return;
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Inventory
              </h2>
            </div>
            <div className="flex justify-end mt-4 gap-3">
              {/* <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-3 w-[300px] shadow-sm">
                <input
                  type="text"
                  placeholder="Search Product"
                  className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#00b0a6]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                  />
                </svg>
              </div> */}

              <button
                type="button"
                onClick={() => {
                  dispatch({ type: "PLACE_ORDER_CHECK", num: true });
                  // router("/Inventory-Categories");
                  // router("/SuperAdmin/Inventory/SelectVendor")
                  router("/Inventory/SelectProgram");
                }}
                className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
              >
                Place Order
              </button>
            </div>

            <div className="mt-3  rounded-md  p-[2rem] pt-[2rem] mb-16  sm:min-h-[360px] bg-[#F6F4F4] shadow-lg">
              <Cards />
              {/* <div className="flex items-center justify-between">
                <h1 className="text-gray-700 font-semibold text-[1.1rem]">
                  Recent Orders
                </h1>
                <p
                  className="text-primary cursor-pointer font-semibold text-[0.93rem]"
                  onClick={() => {
                    router("/Inventory-Vendor-Orders");
                  }}
                >
                  View All
                </p>
              </div>
              <div className="w-full grid grid-cols-5  justify-center items-center gap-8 md:gap-x-4 gap-y-10 sm:min-h-[200px]">
                {items?.map((item, index) => (
                  <div className=" bg-white border border-gray-200 rounded-lg shadow">
                    <img
                      className="rounded-t-lg w-full h-24 object-cover"
                      src={item?.coverPhotos?.[0]}
                      alt=""
                    />

                    <div className="p-2">
                      <div className="flex items-center justify-between">
                        <h5 className="mb-2 text-[0.9rem] font-semibold tracking-tight text-gray-700 ">
                          {item?.name}
                        </h5>
                        <p>
                          ${item?.price}/ {item?.amount}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p>Quantity</p>
                        <div className="inline-flex items-center px-8 py-1 text-sm font-medium text-center text-white bg-primary rounded-lg ">
                          120
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              {/* <div className="flex items-center justify-between mt-3">
                <h1 className="text-gray-700 font-semibold text-[1.1rem]">
                  Recent Vendors
                </h1>
                <p
                  className="text-primary cursor-pointer font-semibold text-[0.93rem]"
                  onClick={() => {
                    router("/SuperAdmin/Inventory/VendorListing");
                  }}
                >
                  View All
                </p>
              </div>
              <Table /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
