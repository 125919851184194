import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import done from "../../../imgs/done.svg";
import gallary from "../../../imgs/picture.png";
import device from "../../../imgs/sign.png";
import codeHandler from "../../Register/codeHandler/codehandle";
import { Button } from "antd";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
import SignatureWhite from "../../../imgs/signatureWhite.png";
import SignatureBlack from "../../../imgs/signatureBlack.png";
import fingerprintWhite from "../../../imgs/fingerprintWhite.png";
import axios from "axios";

let file;
const SuccessModal = ({
  setShow,
  err,
  setValue,
  verify,
  data,
  submit,
  display,
  setDisplay,
  setSignature,
  signature,
  exceedPurchaseLimit,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const router = useNavigate();
  const veri = useSelector((state) => state.verify);
  const [sig, setSig] = useState(true);
  const [sigD, setSigD] = useState(false);
  const [gal, setGal] = useState(false);
  const [pop, setPop] = useState(false);
  const profileData = useSelector((state) => state.profileData);

  const downloadFile = () => {
    const url = "https://sap-api-staging.jabcomp.gy/public/biometric-sdk.exe";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "biometric-sdk.exe");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }

  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }

  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setDisplay("");
      })
      .catch(function () {});
  }

  const imageHandler = (setState, setUrl, load) => async (e) => {
    setPop(false);
    setSigD(false);
    // load(true);
    file = e.target.files[0];
    console.log("Fileee", file);
    if (file === undefined) {
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      console.log("base", base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          setGal(true);
          load(false);
        })
        .catch(() => {});
    }
  };

  const imageHandlerSig = (file) => async () => {
    console.log("OKKKK");
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setSignature(data.data.url);
      })
      .catch(() => {});
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const submitHandler = () => {
    const api = "v1/employee-info/update-signature";
    const data = {
      signature,
    };

    axios
      .put(`${process.env.REACT_APP_BASE_URL}/${api}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch({
          type: "setProfileData",
          num: {
            ...profileData,
            employeeInfo: {
              ...profileData.employeeInfo,
              signature, // Update the signature in employeeInfo
            },
          },
        });
        submit();
      })
      .catch((err) => {});
  };

  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl w-[40rem] flex items-center p-6 bg-white flex-col max-h-[90vh] overflow-y-auto">
        <h2 className="text-center  mb-5 mt-4 text-[1rem] font-semibold px-8">
          Verify Order
        </h2>
        <p
          className={`${
            exceedPurchaseLimit
              ? "text-red-500 p-2 rounded-sm bg-red-50 mb-3"
              : "hidden"
          }`}
        >
          Note: Your order exceeds your purchase limit and requires PS approval.
          It will be placed only if approved
        </p>
        <p className="text-center">
          Please provide your signature here to confirm that you have placed
          this order.
        </p>

        <div className="flex items-center justify-center flex-col">
          <h2
            className={`
             text-[0.9rem]
             text-center mb-6 text-[#F25A29] font-semibold `}
          >
            Please upload Signature
          </h2>
          <div className="sm:w-full flex flex-col items-center justify-center">
            <div className="w-full flex items-center justify-center gap-12">
              {veri && (
                <div
                  onClick={() => {
                    setSig(true);
                  }}
                  className={`w-[4rem] h-[4rem] ${
                    veri ? "bg-primary" : "bg-white"
                  } p-2 shadow-lg rounded-md cursor-pointer flex items-center justify-center`}
                >
                  <img src={veri ? SignatureWhite : SignatureBlack} alt="" />
                </div>
              )}
              {!veri && (
                <div
                  onClick={() => {
                    setSig(false);
                  }}
                  className={`w-[4rem] h-[4rem] ${
                    !veri ? "bg-primary" : "bg-white"
                  } p-2 shadow-lg rounded-md cursor-pointer flex items-center justify-center`}
                >
                  <img
                    src={!veri ? fingerprintWhite : fingerprintWhite}
                    alt=""
                  />
                </div>
              )}
            </div>
            {gal && veri ? (
              <h2
                className={`
             text-[0.9rem]
             text-center mb-2 mt-2 text-[#F25A29] font-semibold `}
              >
                Uploaded from gallery
              </h2>
            ) : (
              ""
            )}
            {veri && (
              <div
                className={`border-dashed bg-white mt-4 border-2 border-primary flex items-center justify-center rounded-lg text-center sm:w-[23rem] sm:h-[16rem] w-[18rem] h-[13rem] relative`}
              >
                {display ? (
                  <>
                    <img
                      src={display}
                      alt="Uploaded"
                      className="w-full h-full"
                    />
                    <button
                      className="absolute bottom-1 right-1"
                      onClick={() => {
                        setSignature("");
                        setDisplay("");
                        setGal(false);
                      }}
                    >
                      <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                    </button>
                  </>
                ) : (
                  <div className="w-[80%] flex flex-col gap-1  items-center justify-center">
                    {/* <UploadOutlined className="text-5xl text-primary cursor-pointer " /> */}
                    <div className="flex items-center justify-center">
                      <label
                        // htmlFor="file-upload"
                        className="text-5xl text-primary cursor-pointer"
                      >
                        <UploadOutlined />
                      </label>
                      {/* <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={imageHandler(
                        setDisplay,
                        setSignature,
                        setIsLoading
                      )}
                    /> */}
                    </div>
                    <p>Upload Signature </p>
                    <Button
                      onClick={() => {
                        setPop(true);
                      }}
                      className="mt-4 w-[80%] cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg"
                    >
                      Upload
                    </Button>
                  </div>
                )}
              </div>
            )}
            {sigD && veri && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    GetWhitSigPic();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Open Pad
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ConfirmSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Get Sign
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ReSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Reset
                </button>
              </div>
            )}
            {sigD && veri && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <p
                  onClick={downloadFile}
                  className="text-primary hover:underline cursor-pointer font-semibold"
                >
                  Download SDK
                </p>
              </div>
            )}
          </div>
        </div>

        <div
          className={`${
            pop ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
            <h1 className="text-xl text-primary font-semibold">Choose</h1>
            <div className="w-[80%] flex items-center justify-center mt-10 mb-10 gap-6">
              <img
                onClick={() => {
                  setSigD(true);
                  setPop(false);
                }}
                src={device}
                alt=""
                className="w-18 h-14 cursor-pointer"
              />
              <label>
                <img
                  src={gallary}
                  alt=""
                  className="w-14 h-14 cursor-pointer"
                />
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={imageHandler(setDisplay, setSignature)}
                />
              </label>
            </div>
            <div className="flex items-center justify-end w-[80%]">
              <button
                onClick={() => setPop(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary"
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center  w-full">
          <button
            // onClick={() => router("/Inventory-Vendor-Orders")}
            onClick={() => {
              setShow(false);
            }}
            className={`lg:text-[1rem] bg-red-600 text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[50%]`}
          >
            Cancel
          </button>
          <button
            className={`lg:text-[1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[50%]`}
            onClick={() => {
              if (profileData?.user?.roles?.[0] === "employee") {
                submitHandler();
                return;
              }
              submit();

              // router("/PaymentOrder")
            }}
          >
            {exceedPurchaseLimit ? "Confirm & Send for approval" : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
