import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";

import Table from "./table";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
const Listing = () => {
  const PsOrders = useSelector((state) => state.PsOrders);
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);

  const [status, setStatus] = useState(null);

  const statuses = [
    { title: "Pending", count: 200 },
    {
      title: user === "superadmin" ? "Quotation" : "Quotation Requested",
      count: 300,
    },
    { title: "Cancelled", count: 120 },
    // { title: "In-Progress", count: 60 },
    // { title: "Shipped", count: 10 },
    { title: "Delivered", count: 70 },
    { title: "Partial", count: 70 },
    { title: "Delivered-Issue reported", count: 70 },
  ];

  const StatusCard = ({ title, count }) => {
    return (
      <div className="bg-[#D9EFED] border  text-center p-4 rounded-lg shadow-md sm:w-48 w-28 h-24 sm:h-30 flex flex-col justify-center">
        {/* Title Section */}
        <p className="text-gray-500 font-semibold text-sm sm:h-12 ">{title}</p>

        {/* Count Section */}
        <p className="text-teal-600 font-bold md:text-3xl text-md">{count}</p>
      </div>
    );
  };

  const items = [
    {
      id: 1,
      coverPhotos: [
        "https://images.unsplash.com/photo-1588165171080-c89acfa5ee83?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyYXdiZXJyeXxlbnwwfHwwfHx8MA%3D%3D",
        "https://i.pinimg.com/originals/c3/5c/84/c35c84121aaea699769bf2623bb8bd56.png",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg/1200px-Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg",
      ],
      name: "Strawberry",
      price: 23,
      quantity: 1,
      items: 23,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 2,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdYAUXvlwNIrC2A_4oIhFUyBtKtLNHm0buQ&s",
        "https://images.unsplash.com/photo-1571771894821-ce9b6c11b08e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmFuYW5hc3xlbnwwfHwwfHx8MA%3D%3D",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuFJ45D9B5tq0e9ijztRbX_y7YTbGZzn0T1w&s",
      ],
      name: "Banana",
      price: 22,
      items: 12,
      routeV: "/Inventory-Vendor-Product",
      quantity: 1,
      amount: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 3,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuIg-NlWsGyHPdsYHRMPHoynmyVvtx9GLNRA&s",
        "https://cdn.britannica.com/24/174524-050-A851D3F2/Oranges.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoYq2s3xqHMWVzlAUXGh-LnB20Z3nhC8Y46liCklv90f0ZSaxThNe1EFCAETyg5vnHlK8&usqp=CAU",
      ],
      name: "Orange",
      price: 43,
      items: 97,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 4,
      coverPhotos: [
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Mangos_-_single_and_halved.jpg/640px-Mangos_-_single_and_halved.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmgfExstNMpVm0dVH-xKurYdLlQpZpgJxHaw&s",
        "https://www.finedininglovers.com/sites/g/files/xknfdk626/files/styles/article_1200_800_fallback/public/2021-06/best-mango-varieties%C2%A9iStock.jpg?itok=KHYB1X__",
      ],
      name: "Mango",
      price: 33,
      items: 56,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
      ],
      name: "Grapes",
      price: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
  ];

  const vendorOwnerStatuses = [
    { value: null, label: "All" },
    { value: "submitted", label: "Submitted" },
    { value: "cancelled", label: "Cancelled" },
    { value: "in-progress", label: "In Progress" },
    { value: "delivered", label: "Delivered" },
    { value: "delivered-issue", label: "Delivered with Issue" },
    { value: "shipped", label: "Shipped" },
    { value: "received", label: "Received" },
  ];

  const employeePSStatuses = [
    { value: null, label: "All" },
    { value: "awaited-ps", label: "Awaited PS" },
    { value: "ps-rejected", label: "PS Rejected" },
    { value: "ps-approved", label: "PS Approved" },
    { value: "re-submitted", label: "Re-Submitted" },
  ];

  const otherStatuses = [
    { value: null, label: "All" },
    { value: "awaited-ps", label: "Awaited PS" },
    { value: "ps-approved", label: "PS Approved" },
    { value: "ps-rejected", label: "PS Rejected" },
    { value: "re-submitted", label: "Re-Submitted" },
    { value: "submitted", label: "Submitted" },
    { value: "in-progress", label: "In Progress" },
    { value: "partial", label: "Partial" },
    { value: "shipped", label: "Shipped" },
    { value: "delivered-issue", label: "Delivered with Issue" },
    { value: "delivered", label: "Delivered" },
    { value: "received", label: "Received" },
    { value: "cancelled", label: "Cancelled" },
  ];

  let statusesOptions;

  if (user === "vendor-owner") {
    statusesOptions = vendorOwnerStatuses;
  } else if (user === "employee" && role === "Permanent Secretary") {
    statusesOptions = employeePSStatuses;
  } else {
    statusesOptions = otherStatuses;
  }

  return (
    <>
      <div>
        <div className="flex items-center justify-center bg-[#D9EFED] ">
          <div className="w-[95%] mt-[1rem] sm:px-10 px-4 rounded-md mb-12 ">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor-owner") {
                    router("/VendorDashboard");
                    return;
                  }
                  if (user === "employee" && role === "Permanent Secretary") {
                    router("/Inventory/PSOrders");
                    return;
                  }
                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                {user === "employee" || user === "admin" || user === "superadmin"
                  ? PsOrders?.title
                  : "Orders"}
              </h2>
            </div>
            {/* {user === "superadmin" && ( */}
            <div className="flex justify-end mt-4 gap-3 flex-col-reverse sm:flex-row">
              <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
                <div className="flex sm:w-[14rem] w-[14rem] items-center">
                  <p className="mr-4 text-[0.95rem] font-semibold text-gray-600 w-[3rem]">
                    Filter
                  </p>
                  <Select
                    placeholder="Filter Status"
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={status}
                    onChange={setStatus}
                    size="middle"
                    className="w-full"
                    options={statusesOptions}
                  />
                </div>
              </div>
              <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-2 w-[300px] shadow-sm">
                <input
                  type="text"
                  placeholder="Search Order"
                  className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#1BA397]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.8"
                    d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                  />
                </svg>
              </div>
              <button
                type="button"
                onClick={() => {
                  dispatch({ type: "PLACE_ORDER_CHECK", num: true });
                  // router("/Inventory-Categories");
                  // router("/SuperAdmin/Inventory/SelectVendor")
                  router("/Inventory/SelectProgram");
                }}
                className={`${
                  user === "superadmin"
                    ? "cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
                    : "hidden"
                }`}
              >
                Place Order
              </button>
            </div>
            {/* )} */}

            <div className="bg-[#F6F4F4] mt-3 rounded-md p-2">
              <div
                className={`${user === "superadmin" ? "hidden " : "hidden"}`}
              >
                <div className="flex items-center justify-between">
                  <h1 className="text-gray-700 font-semibold text-[1.1rem] mt-2">
                    Recent Orders
                  </h1>
                </div>
                <div className="bg-white p-4 px-6 rounded-sm w-full grid grid-cols-2 md:grid-cols-5 sm:grid-cols-3  justify-center items-center gap-8 md:gap-x-4 gap-y-10 sm:min-h-[200px]">
                  {items?.map((item, index) => (
                    <div className=" bg-white border border-gray-200 rounded-lg shadow">
                      <img
                        class="rounded-t-lg w-full sm:h-24 h-16 object-cover"
                        src={item?.coverPhotos?.[0]}
                        alt=""
                      />

                      <div className="p-2">
                        <div className="flex items-center justify-between">
                          <h5 className="mb-2 text-[0.9rem] font-semibold tracking-tight text-gray-700 ">
                            {item?.name}
                          </h5>
                          <p>
                            ${item?.price}/ {item?.amount}
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p>Quantity</p>
                          <div className="inline-flex items-center sm:px-8 px-4 py-1 text-sm font-medium text-center text-white bg-primary rounded-lg ">
                            120
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="sm:flex grid grid-cols-3 sm:space-x-4 sm:justify-center gap-3 pl-6 pr-6 pt-6 pb-2">
                {statuses.map((status, index) => (
                  <StatusCard
                    key={index}
                    title={status.title}
                    count={status.count}
                  />
                ))}
              </div>

              <Table status={status} setStatus={setStatus} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
