import eye from "../../../../../imgs/eyee.png";
import across from "../../../../../imgs/across.png";
import { useState } from "react";
import { useSelector } from "react-redux";
import cross from "../../../../../imgs/cross.png";
import logo1 from "../../../../../imgs/logo12.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SuccessModal from "../../../Modals/successModal";
import VerifyPassword from "./Modals/VerifyPassword";
import ResetPin from "./Modals/ResetPin";
const Personal = ({ data, check }) => {
  const SelectedVendorData = useSelector((state) => state?.SelectedVendorData);
  console.log("SelectedVendorData", SelectedVendorData);
  const location = useLocation();
  const state = location.state;
  const employeeProfile = state && state.employeeProfile;
  const [oldPass, setOldPass] = useState("");
  const token = useSelector((state) => state.token);
  const [confirmPass, setConfirmPass] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  const isGovernmentAgency = useSelector((state) => state.isGovernmentAgency);
  const [newPass, setNewPass] = useState("");
  const [showPopReset, setShowPopReset] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [value, setValue] = useState("");
  const [showPV, setShowPv] = useState(false);
  const [showPopRPin, setShowPopRPin] = useState(false);
  const [pin, setPin] = useState("");
  const [showS, setShowS] = useState(false);
  const [pass, setPass] = useState("");
  const user = useSelector((state) => state.user);
  const dash = location.pathname;
  const togglePasswordVisibility = (setter) => {
    setter((prev) => !prev);
  };

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };
  console.log("userData", dataUser);
  const resetHandler = () => {
    if (newPass !== confirmPass) {
      setShow(true);
      setErr("Please confirm password again");
    } else {
      const api =
        user === "vendor-owner"
          ? "v1/vendor-auth/change-password"
          : "v1/merch-auth/change-password";
      const tok =
        user === "vendor-owner"
          ? dataUser?.data?.vendorTokens?.access?.token
          : dataUser?.data?.merchTokens?.access?.token;
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/${api}`,
          {
            oldPassword: oldPass,
            newPassword: newPass,
          },
          {
            headers: {
              Authorization: `Bearer ${tok}`,
            },
          }
        )
        .then(() => {
          setShowPopReset(false);
          setNewPass("");
          setOldPass("");
          setConfirmPass("");
          setShowS(true);
          setErr("Your password updated successfully");
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };

  // const generatePassword = () => {
  //   const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  //   const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  //   const numbers = "0123456789";
  //   const symbols = "!@#$%^&*()-=_+[]{}|;:,.<>?";

  //   const allChars = lowercaseLetters + uppercaseLetters + numbers + symbols;

  //   let newPassword = "";
  //   for (let i = 0; i < 9; i++) {
  //     const randomIndex = Math.floor(Math.random() * allChars.length);
  //     newPassword += allChars[randomIndex];
  //   }
  // };

  const handleShare = () => {
    const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()-=_+[]{}|;:,.<>?";

    // Combine all character sets
    const allChars = lowercaseLetters + uppercaseLetters + numbers + symbols;

    let newPassword = "";

    // Add at least one character from each required set
    newPassword +=
      lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
    newPassword +=
      uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
    newPassword += numbers[Math.floor(Math.random() * numbers.length)];
    newPassword += symbols[Math.floor(Math.random() * symbols.length)];

    // Add remaining characters to complete the length
    for (let i = 0; i < 9 - 4; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      newPassword += allChars[randomIndex];
    }

    // Shuffle the password to ensure randomness
    newPassword = newPassword
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/merch-user/credentials?merchUserId=${data?.id}`,
        {
          password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setShowSuccessModal(true);
        setErr("Login details sent to employee successfully");
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  return (
    <div>
      <div className="">
        <div className="container mx-auto py-8">
          <div className="flex flex-col gap-6 sm:px-4 relative">
            {/* <div className="col-span-4 sm:col-span-3 h-full">
              <div className="bg-white rounded-lg p-6 border shadow-md p-4">
                <div className="flex flex-col items-center">
                  <img
                    src={data?.image}
                    className="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0"
                    alt=""
                  />

                  <h1 className="text-lg font-bold text-center text-gray-600 whitespace-normal capitalize">
                    {`${data?.firstName ? data?.firstName : ""} ${
                      data?.middleName ? data.middleName : ""
                    } ${data?.lastName ? data?.lastName : ""} ${data?.lastName ? data?.lastName : ""} ${data?.lastName ? data?.lastName : ""} ${data?.lastName ? data?.lastName : ""}`}
                  </h1>

                  <p className="text-primary font-semibold">Merchant</p>
                </div>

                <hr className="my-6 border-t border-gray-300" />
                <div className="flex flex-col">
                  <span className="text-gray-700 font-bold tracking-wider mb-2">
                    Gender
                  </span>
                  <span className="text-primary font-semibold tracking-wider mb-2">
                    {data?.gender}
                  </span>
                  <span className="text-gray-700 font-bold tracking-wider mb-2">
                    DOB
                  </span>
                  <span className="text-primary font-semibold tracking-wider mb-2">
                    {data?.dateOfBirth}
                  </span>
                </div>
              </div>
            </div> */}
            <div
              className={`${employeeProfile === true ? "ml-auto" : "hidden"}`}
            >
              <button
                className="bg-primary text-white px-3 py-2 rounded-lg text-medium text-[0.9rem]"
                onClick={handleShare}
              >
                Share Login Details
              </button>
            </div>
            <div className="col-span-4 sm:col-span-9 ">
              <div className="p-6 mb-8 relative bg-white rounded-lg border shadow-md">
                <h2 className="text-xl font-bold mb-4">Email & Phone</h2>
                {/* <ContactInfo company={data} /> */}
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm text-gray-700 font-bold">
                        Email address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {/* {check === "owner"
                          ? dataUser?.data?.merchuser?.email
                          : user === "vendor-owner"
                          ? dataUser?.data?.vendorUser?.email
                          : data?.email} */}

                        {user === "vendor-owner"
                          ? dataUser?.data?.vendorUser?.email
                          : user === "superadmin" &&
                            dash === "/VendorDashboard/profile"
                          ? SelectedVendorData?.vendorUser?.email
                          : check === "owner"
                          ? dataUser?.data?.merchuser?.email
                          : data?.email}
                      </dd>
                    </div>
                    <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm text-gray-700 font-bold">
                        Phone number
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {/* {check === "owner"
                          ? dataUser?.data?.merchuser?.countryCode +
                            " " +
                            dataUser?.data?.merchuser?.phoneNo
                          : data?.countryCode + " " + data?.phoneNo} */}
                        {user === "vendor-owner"
                          ? dataUser?.data?.vendorUser?.countryCode +
                            " " +
                            dataUser?.data?.vendorUser?.phoneNo
                          : user === "superadmin" &&
                            dash === "/VendorDashboard/profile"
                          ? SelectedVendorData?.vendorUser?.countryCode +
                            " " +
                            SelectedVendorData?.vendorUser?.phoneNo
                          : check === "owner"
                          ? dataUser?.data?.merchuser?.countryCode +
                            " " +
                            dataUser?.data?.merchuser?.phoneNo
                          : data?.countryCode + " " + data?.phoneNo}
                      </dd>
                    </div>
                  </dl>
                </div>
                {/* <p className="absolute top-0 right-0 p-4 text-primary  text-[0.9rem]  font-semibold cursor-pointer">
                  Edit
                </p> */}
              </div>
              <div
                className={`${
                  employeeProfile === true ||
                  (user === "superadmin" && dash === "/VendorDashboard/profile")
                    ? "hidden"
                    : "bg-white rounded-lg p-6 relative border shadow-md mb-8"
                }`}
              >
                <h2 className="text-xl font-bold mb-4">Password</h2>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm text-gray-700 font-bold">
                        Password
                      </dt>
                      <dd className="text-xl font-bold text-gray-900 sm:mt-0 sm:col-span-2 list-disc list-inside text-blue-500">
                        **********
                      </dd>
                    </div>
                  </dl>
                </div>
                <p
                  onClick={() => setShowPopReset(true)}
                  className="absolute top-0 right-0 p-4 text-primary  text-[0.9rem]  font-semibold cursor-pointer"
                >
                  Change Password
                </p>
              </div>
              <div
                className={`${
                  employeeProfile === true ||
                  user === "merchant-employee" ||
                  user === "merchant-admin" ||
                  isGovernmentAgency ||
                  user === "vendor-owner" ||
                  (user === "superadmin" && dash === "/VendorDashboard/profile")
                    ? "hidden"
                    : "bg-white rounded-lg p-6 relative border shadow-md"
                }`}
              >
                <h2 className="text-xl font-bold mb-4">PIN</h2>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm text-gray-700 font-bold">PIN</dt>
                      <dd className="text-xl font-bold text-gray-900 sm:mt-0 sm:col-span-2 list-disc list-inside ">
                        **********
                      </dd>
                    </div>
                  </dl>
                </div>
                <p
                  onClick={() => setShowPv(true)}
                  className="absolute top-0 right-0 p-4 text-primary  text-[0.9rem]  font-semibold cursor-pointer"
                >
                  Reset Pin
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopReset && (
        <div
          className={`w-full h-full fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <h2 className="mb-[1rem] text-[1.5rem] font-bold">
              Change Password
            </h2>
            <div
              className="absolute top-3 right-4 cursor-pointer"
              onClick={() => {
                setShowPopReset(false);
              }}
            >
              <img className="scale-[0.8]" src={cross} alt="Close" />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="oldPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mt-2 mb-2"
              >
                Current Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type={showOldPass ? "text" : "password"}
                  placeholder="Current Password"
                  onChange={handleChange(setOldPass)}
                  value={oldPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowOldPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="newPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                New Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type={showNewPass ? "text" : "password"}
                  placeholder="New Password"
                  onChange={handleChange(setNewPass)}
                  value={newPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowNewPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="confirmPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Confirm Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type={showConfirmPass ? "text" : "password"}
                  placeholder="Confirm Password"
                  onChange={handleChange(setConfirmPass)}
                  value={confirmPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowConfirmPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <button
              onClick={resetHandler}
              className="w-[83%] bg-primary text-white p-3 mb-4 text-medium text-[1.1rem] rounded-lg mt-5"
            >
              Done
            </button>
          </div>
        </div>
      )}
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          setShow={setShowSuccessModal}
          err="Credentials shared successfully"
          setValue={setValue}
        />
      )}

      {/* Reset Pin Modal */}
      {showPopRPin && (
        <ResetPin
          setShow={setShowPopRPin}
          pin={pin}
          setPin={setPin}
          setShowS={setShowS}
          setPass={setPass}
          pass={pass}
          setErr={setErr}
          setErrorShow={setShow}
        />
      )}

      {/* Verify Password Modal */}
      {showPV && (
        <VerifyPassword
          setShowPopRPin={setShowPopRPin}
          setPin={setPin}
          setShow={setShowPv}
          setErr={setErr}
          setErrorShow={setShow}
          setPass={setPass}
          pass={pass}
        />
      )}

      {/* Success Message Modal */}
      <div
        className={`${
          showS ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div>
            <img src={logo1} alt="" className="w-20 h-20" />
          </div>
          <div className="text-center mt-[1rem] mb-5">
            <p>
              <strong> {err} </strong>
            </p>
          </div>
          <div>
            <button
              onClick={() => {
                setShowS(false);
              }}
              className="w-[6rem] bg-primary text-white px-5 py-1 rounded-md"
            >
              Okay
            </button>
          </div>
        </div>
      </div>

      {/* Error Message Modal */}
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] z-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Personal;
