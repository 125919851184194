import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import fav from "../../../imgs/heartW.png";
import ProductDetail from "./productDetails";
import cartW from "../../../imgs/cartW.png";
import Footer from "../cart/footer";
const EventDetails = () => {
  const router = useNavigate();
  const product = useSelector((state) => state.product);
  const cart = useSelector((state) => state.cartData);
  const user = useSelector((state) => state.user);
  return (
    <div className="flex items-center justify-center pt-[2rem] flex-col bg-[#D9EFED]">
      <div className="sm:w-[85%] flex flex-col items-center justify-center mb-4">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                router("/Inventory-Products");
                // router("/Inventory")
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Product Details
              </h2>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => {
                  router("/Inventory-Favorite");
                }}
                className={` text-white bg-primary p-2 pl-3 pr-3 rounded-lg sm:text-lg text-md `}
              >
                <img src={fav} alt="" className="w-5 h-5" />
              </button>

              <button
                onClick={() => {
                  router("/Inventory-Cart");
                }}
                className={` text-white bg-primary p-2 pl-3 pr-3 rounded-lg sm:text-lg text-md `}
              >
                <img src={cartW} alt="" className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:w-[85%] pb-[4rem] bg-[#F6F4F4] mb-5 p-6 rounded-md">
        <ProductDetail productData={product} />
      </div>
      {cart?.length > 0 && user !== "vendor-owner" && <Footer />}
    </div>
  );
};

export default EventDetails;
