import React from "react";

const Address = ({ headquarter, title }) => {
  console.log(headquarter);
  const Div = ({ title, value }) => (
    <div className="sm:px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-semibold leading-6 text-gray-900">{title}</dt>
      <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
        {value}
      </dd>
    </div>
  );

  return (
    <div className="p-6 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
      <div className="sm:px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-600">
          {title}
        </h3>
        {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Personal details and application.
          </p> */}
      </div>
      <div className="mt-6 border-t border-gray-300">
        <dl className="divide-y divide-gray-300">
          <Div title="Lot #" value={headquarter?.lot} />
          <Div title="Address" value={headquarter?.address} />
          <Div
            title="Region No"
            value={
              title === "Address" ? headquarter?.regionNo : headquarter?.region
            }
          />
          <Div title="Region Name" value={headquarter?.regionName} />
          <Div title="Town / City" value={headquarter?.city} />
          <Div
            title="Ward / Village"
            value={
              title === "Address" ? headquarter?.ward : headquarter?.village
            }
          />
        </dl>
      </div>
    </div>
  );
};

export default Address;
