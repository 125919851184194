import React from "react";
import pencil from "../../../../imgs/pencil.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
const ExistingTierTab = ({
  data,
  setShow,
  setErr,
  setRefetch,
  refetch,
  setShowSuccessModal,
  setShowErrorModal,
}) => {
  const token = useSelector((state) => state.token);
  const DeletePrice = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-product/delete-advance-price/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setErr(`Tier Price Deleted Successfully`);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };
  const TierCard = ({ item }) => {
    return (
      <div className="  p-4 py-5 rounded-lg bg-[#EEEDED] shadow relative">
        <div className="flex justify-between items-center">
          <h3 className="text-gray-800 font-bold">{item?.levelName}</h3>
          <div className="flex items-center justify-between w-[25%]">
            <span className="text-gray-700 font-medium">Price</span>
            <span className="text-primary font-bold">${item?.price}</span>
          </div>
          <div
            className=" top-2 right-3 flex gap-3"
            onClick={() => {
              //   setShowModal(true);
            }}
          >
            {/* <img src={pencil} alt="edit" /> */}
            <DeleteFilled
              style={{ fontSize: "16px", color: "#FF0000" }}
              onClick={() => DeletePrice(item?.id)}
            />
          </div>
          {/* <div
            className=" top-2 right-3"
            onClick={() => {
              //   setShowModal(true);
            }}
          >
            <DeleteFilled style={{ fontSize: "16px", color: "#08c" }} />
          </div> */}
        </div>

        <div className="mt-2 text-gray-600">
          <div className="flex items-center justify-between w-[65%]">
            <span className="text-gray-600">Type:</span>
            <span className="text-gray-700 font-semibold capitalize">
              {item?.type}
            </span>
          </div>
          <div className="flex items-center justify-between w-[65%]">
            <span className="text-gray-600">Min Quantity (kg):</span>
            <span className="text-gray-700 font-semibold">{item?.minimum}</span>
          </div>
          <div className="flex items-center justify-between w-[65%]">
            <span className="text-gray-600">Max Quantity (kg):</span>
            <span className="text-gray-700 font-semibold">{item?.maximum}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 ">
      <div className="grid md:grid-cols-3 gap-5">
        {data?.map((item, index) => (
          <TierCard key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default ExistingTierTab;
