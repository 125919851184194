import { useState } from "react";
import ImagePreview from "../../Modals/imagePreview";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Documentation = ({ documentation }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const dash = location.pathname;
  return (
    <div className="sm:p-10 ">
      <div className="bg-[#F4F5FE] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 ipad-pro:grid-cols-2 gap-4 p-4 rounded-lg ml-4 mr-4 px-5">
        {documentation?.map((document, index) => (
          <div
            key={index}
            className="relative flex flex-col border p-5 rounded-md bg-white"
          >
            <div className="p-2">
              <h1 className="font-bold text-sm text-gray-900 mb-2 capitalize">
                {/* {document?.type === "business registration information"
                  ? "BRI"
                  : document?.type === "value-added tax"
                  ? "VAT"
                  : document?.type} */}
                {document?.type}
              </h1>
              <h3 className="font-semibold text-md text-gray-900">
                No : <span>{document?.docIDValue}</span>
              </h3>
              {/* <h3 className="font-semibold text-md text-gray-900">Expiry Date : <span>{document?.expiry}</span></h3> */}
              <h3 className="font-semibold text-md text-gray-900 mt-2">
                Attachments
              </h3>
            </div>

            <div className="flex gap-3">
              <div className="flex-1">
                <h1
                  className={`font-semibold whitespace-nowrap text-primary ${
                    document?.merchcompanydocimgs?.[0] ||
                    ((user === "vendor-owner" || user === "superadmin") &&
                      document.vendordocimages?.[0])
                      ? ""
                      : "hidden"
                  }`}
                >
                  Front Side
                </h1>

                <div className={`relative mb-4`}>
                  <img
                    onClick={() => {
                      // setImage(
                      //   document?.merchcompanydocimgs?.[0]?.imageUrl?.url
                      // );
                      setImage(
                        user === "vendor-owner" || dash === "/VendorDashboard/profile"
                          ? document.vendordocimages?.[0]?.imageUrl?.url
                          : document?.merchcompanydocimgs?.[0]?.imageUrl?.url
                      );
                      setTitle("Front Side");
                      setShowPreview(true);
                    }}
                    className={`w-24 h-24 cursor-pointer ${
                      document?.merchcompanydocimgs?.[0] ||
                      ((user === "vendor-owner" || user === "superadmin") && document.vendordocimages?.[0])
                        ? ""
                        : "hidden"
                    }`}
                    src={
                      user === "vendor-owner" || dash === "/VendorDashboard/profile"
                        ? document.vendordocimages?.[0]?.imageUrl?.url
                        : document?.merchcompanydocimgs?.[0]?.imageUrl?.url
                    }
                    alt=""
                  />
                  <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => {}}
                  >
                    {/* <img className="w-[2rem]" src={trash} alt="" /> */}
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <h1
                  className={`font-semibold p-2 text-primary  ${
                    document?.merchcompanydocimgs?.[1] ||
                    ((user === "vendor-owner" || user === "superadmin") && document.vendordocimages?.[1])
                      ? ""
                      : "hidden"
                  }`}
                >
                  Back Side
                </h1>

                <div className={`relative mb-4`}>
                  <img
                    onClick={() => {
                      // setImage(
                      //   document?.merchcompanydocimgs?.[1]?.imageUrl?.url
                      // );
                      setImage(
                        user === "vendor-owner" || dash === "/VendorDashboard/profile"
                          ? document.vendordocimages?.[1]?.imageUrl?.url
                          : document?.merchcompanydocimgs?.[1]?.imageUrl?.url
                      );
                      setTitle("Back Side");
                      setShowPreview(true);
                    }}
                    className={`w-24 h-24 cursor-pointer ${
                      document?.merchcompanydocimgs?.[1] ||
                      ((user === "vendor-owner" || user === "superadmin")  && document.vendordocimages?.[1])
                        ? ""
                        : "hidden"
                    }`}
                    // src={document?.merchcompanydocimgs?.[1]?.imageUrl?.url}
                    src={
                      user === "vendor-owner" || dash === "/VendorDashboard/profile"
                        ? document.vendordocimages?.[0]?.imageUrl?.url
                        : document?.merchcompanydocimgs?.[0]?.imageUrl?.url
                    }
                    alt=""
                  />
                  <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => {}}
                  >
                    {/* <img className="w-[2rem]" src={trash} alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showPreview && (
        <ImagePreview setShow={setShowPreview} img={image} title={title} />
      )}
    </div>
  );
};

export default Documentation;
