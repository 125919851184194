import logo1 from "../../../imgs/logo12.png";
import arrow from "../../../imgs/Vector.png";
import hamB from "../../../imgs/hamBlack.png";
import hamW from "../../../imgs/hamWhite.png";
import cam1 from "../../../imgs/cam1.png";
import delivery from "../../../imgs/delivery.svg";
import taxBuyer from "../../../imgs/taxBuyer.svg";
import venProfile from "../../../imgs/venProfile.svg";
import venLogout from "../../../imgs/venLogout.svg";
import venReq from "../../../imgs/venReq.svg";
import paymentTerm from "../../../imgs/paymentTerm.svg";
import { Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bellIcon from "../../../imgs/bellIcon.svg";
import ErrorModal from "../../Merchant/Modals/errorModal";
import { LogoutOutlined, UserOutlined, SettingFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
let items;
const { SubMenu } = Menu;
const Nav = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const dataUser = useSelector((state) => state.dataUser);
  const imagePerson = useSelector((state) => state.imagePerson);
  const namePerson = useSelector((state) => state.namePerson);
 
  const userData =
    user === "vendor-owner"
      ? dataUser?.data?.vendorUser?.person
      : dataUser?.data?.user?.person;

  const Name =
    user === "vendor-owner"
      ? namePerson
      : userData?.firstName + " " + userData?.lastName;
  const image = user === "vendor-owner" ? imagePerson : userData?.image;

  const openKey = null;
  const role = useSelector((state) => state.role);
  const [showx, setShowx] = useState(false);
  const location = useLocation();
  const dash = location.pathname;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");

  if (user === "superadmin" || user === "admin" || user === "employee") {
    items = [
      {
        label: "Logout",
        key: "2",
        icon: (
          <img
            src={venLogout}
            alt="img"
            style={{ width: "20px", height: "20px" }}
          />
        ),
      },
    ];
  } else if (user === "vendor-owner") {
    items = [
      {
        label: "Profile",
        key: "1",
        icon: (
          <img
            src={venProfile}
            alt="img"
            style={{ width: "20px", height: "20px" }}
          />
        ),
      },
      {
        label: "Taxable Buyer Review",
        key: "3",
        icon: (
          <img
            src={taxBuyer}
            alt="img"
            style={{ width: "20px", height: "20px" }}
          />
        ),
      },
      // {
      //   label: "Delivery Charges",
      //   key: "3",
      //   icon: (
      //     <img
      //       src={delivery}
      //       alt="img"
      //       style={{ width: "20px", height: "20px" }}
      //     />
      //   ),
      // },
      {
        label: "Payment Terms",
        key: "4",
        icon: (
          <img
            src={paymentTerm}
            alt="img"
            style={{ width: "20px", height: "20px" }}
          />
        ),
      },
      {
        label: "Quotation Requests",
        key: "5",
        icon: (
          <img
            src={venReq}
            alt="img"
            style={{ width: "20px", height: "20px" }}
          />
        ),
      },
      {
        label: "Logout",
        key: "2",
        icon: (
          <img
            src={venLogout}
            alt="img"
            style={{ width: "18px", height: "18px" }}
          />
        ),
      },
    ];
  }

  const items4 = [
    {
      label: "Purchase Limit",
      key: "1",
      // icon: (
      //   <img
      //     src={vector4}
      //     alt="Scheduler Icon"
      //     style={{
      //       width: "18px",
      //       height: "18px",
      //     }}
      //   />
      // ),
    },
    {
      label: "Taxes",
      key: "2",
      // icon: (
      //   <img
      //     src={vector4}
      //     alt="Scheduler Icon"
      //     style={{
      //       width: "18px",
      //       height: "18px",
      //     }}
      //   />
      // ),
    },
    {
      label: "Tax Detail",
      key: "3",
      // icon: (
      //   <img
      //     src={vector4}
      //     alt="Scheduler Icon"
      //     style={{
      //       width: "18px",
      //       height: "18px",
      //     }}
      //   />
      // ),
    },
  ];

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  //logout
  const handleLogOff = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/vendor-auth/logout`, {
        refreshToken: `${dataUser?.data?.vendorTokens?.refresh?.token}`,
      })
      .then((data) => {
        dispatch({ type: "authStateSet", num: "false" });
        dispatch({ type: "tokenSet", num: "0" });
        dispatch({ type: "tokenRef", num: "0" });
        dispatch({ type: "setUserData", num: [] });
        dispatch({ type: "BRSET", num: "0" });
        dispatch({ type: "RSET", num: 0 });
        dispatch({ type: "ISET", num: 1 });
        dispatch({ type: "BRSET", num: 0 });
        dispatch({ type: "BISET", num: 0 });
        dispatch({ type: "ASET", num: 0 });
        router("/");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };

  const onClick = ({ key }) => {
    if (key === "1") {
      router("/VendorDashboard/profile");
    }
    if (key === "2") {
      if (user === "vendor-owner") {
        handleLogOff();
        return;
      }
      if (user === "superadmin") {
        router("/superAdminDashboard");
        return;
      }
      if (user === "admin") {
        router("/adminDashboard");
        return;
      }
      if (user === "employee") {
        router("/EDashboard");
        return;
      }
    }
    if (key === "3") {
      if (user === "vendor-owner") {
        router("/Inventory/ReviewTax");
        return;
      }
    }
    if (key === "4") {
      if (user === "vendor-owner") {
        router("/Inventory/PaymentTerms");
        return;
      }
    }

    if(key === "5"){
      router("/Inventory/Quotations")
    }
  };

  const onClickMenu4 = ({ key }) => {
    if (key === "1") {
      router("/PaymentLimit");
    }
    if (key === "2") {
      router("/TaxSetting");
    }
    if (key === "3") {
      router("/TaxDetail");
    }
  };

  const handleHomeClick = () => {};

  useEffect(() => {
    if (showx) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showx]);
  return (
    <div
      className={`${
        dash === "/VendorDashboard" ? "" : "bg-[#D9EFED]"
      } relative flex items-center justify-center 
      `}
    >
      <nav className="sm:w-[85%] h-[16vh] w-[95%] flex  justify-between items-center">
        <div
          className="cursor-pointer"
          onClick={() => {
            {
              user === "vendor-owner"
                ? router("/VendorDashboard")
                : user === "superadmin"
                ? router("/SuperAdmin/InventoryDashboard")
                : user === "employee"
                ? router("/SuperAdmin/InventoryDashboard")
                : router("/SuperAdmin/InventoryDashboard");
            }
          }}
        >
          <img className="w-[4.5rem]" src={logo1} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <div
            onClick={() => setShowx(true)}
            className="w-[2.5rem] h-[2.5rem] cursor-pointer flex flex-col justify-center md:hidden"
          >
            <img src={hamB} alt="" />
          </div>
        </div>

        <ul className="md:flex items-center gap-x-6 justify-center hidden">
          <li
            className={`${
              user === "superadmin" || user === "admin"
                ? "text-[1.6rem] font-bold cursor-pointer hover:text-[#F35929] "
                : "hidden"
            }`}
          >
            <Dropdown
              menu={{
                items: items4,
                onClick: onClickMenu4,
              }}
              placement="bottomRight"
              arrow
              className="custom-dropdown"
            >
              <SettingFilled className="text-[28px] flex items-center justify-center" />
            </Dropdown>
          </li>
          <li
            // onClick={togglePopup}
            onMouseDown={(e) => e.preventDefault()}
            className=" relative text-[1rem]  font-bold cursor-pointer"
          >
            {/* <BellFilled className="text-[22px] flex items-center justify-center" /> */}
            <img src={bellIcon} alt="" className="w-9 h-9" />
          </li>

          <li className="relative mt-2 flex items-center justify-center">
            <div className="flex flex-col  items-center justify-center">
              <img
                src={image}
                className="w-[3.3rem] h-[3.3rem] border-2 border-primary p-[1px] rounded-full"
                alt=""
              />
            </div>
            <div className="mt-1 ml-2">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                isOpen={dropdownOpen}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <div className="min-w-[100px] flex items-center capitalize justify-center">
                    <h2 className={`font-bold`}>{Name}</h2>
                    <img
                      src={arrow}
                      alt=""
                      className="ml-1 mt-1 flex items-center justify-center"
                    />
                  </div>
                </a>
              </Dropdown>
              {/* {role === "vendor" && ( */}
              <p className="text-[0.8rem] ml-1.5 font-bold text-[#F25A29] capitalize">
                {user === "vendor-owner"
                  ? "Vendor Owner"
                  : user === "superadmin"
                  ? "Super Admin"
                  : user}
              </p>
              {/* )} */}
            </div>
          </li>
        </ul>
      </nav>
      <div
        className={`${
          showx ? "" : "hidden"
        } fixed top-0 left-0 w-full h-full bg-white z-[300]`}
      >
        <div className="w-full flex justify-between pr-4 pl-4 bg-primary p-6 showdow-md">
          <div className="flex items-center">
            <div className="">
              <img
                className="rounded-full w-[4rem] h-[4rem] border-2 border-[#20B574]"
                src={cam1}
                alt="img"
              />
            </div>
            <p className="pt-1 pb-1 pl-3 pr-3 text-white font-bold rounded-xl">
              {Name}
            </p>
          </div>
          <div
            onClick={() => setShowx(false)}
            className="w-[2rem] h-[2rem] cursor-pointer flex flex-col mt-4 justify-center md:hidden"
          >
            <img src={hamW} alt="" />
            {/* <div className="w-full h-[2px] bg-white"></div>
            <div className="w-full h-[2px] bg-white"></div>
            <div className="w-full h-[2px] bg-white"></div> */}
          </div>
        </div>
        <Menu
          mode="inline"
          className="mt-4 bg-white text-lg p-2"
          openKeys={openKey ? [openKey] : []}
        >
          {user === "vendor-owner" && (
            <Menu.Item
              key="Profile"
              icon={<UserOutlined />}
              onClick={() => {
                router("/VendorDashboard/profile");
              }}
            >
              Profile
            </Menu.Item>
          )}

          <Menu.Item
            key="Logout"
            icon={<LogoutOutlined />}
            onClick={() => {
              if (user === "vendor-owner") {
                handleLogOff();
                return;
              }
              router("/superAdminDashboard");
            }}
          >
            Logout
          </Menu.Item>
          {/* <SubMenu
            key="dropdown"
            title="Languages"
            className="border-b"
            icon={<RedEnvelopeOutlined />}
            // onTitleClick={() => handleSubMenuClick("dropdown")}
          >
            {items.map((item) => (
              <Menu.Item key={item.key} onClick={() => console.log(item.label)}>
                {item.label}
              </Menu.Item>
            ))}
          </SubMenu> */}
        </Menu>
      </div>
      {show && <ErrorModal setShow={setShow} err={err} />}
    </div>
  );
};

export default Nav;
