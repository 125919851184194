import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Nav from "../../vendor/navbar";
import Table from "./main";
import { useDispatch, useSelector } from "react-redux";

const Listing = () => {
  const PsOrders = useSelector((state) => state.PsOrders);
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);

  return (
    <>
      <div className="bg-[#D9EFED] min-h-screen">
      <Nav />
        <div className="flex items-center justify-center bg-[#D9EFED] ">
          <div className="w-[95%] mt-[1rem] sm:px-10 px-4 rounded-md mb-12 ">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor-owner") {
                    router("/VendorDashboard");
                    return;
                  }
                  if (user === "employee" && role === "Permanent Secretary") {
                    router("/Inventory/PSOrders");
                    return;
                  }
                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Quotation Requests
              </h2>
            </div>

            <div className="bg-[#F6F4F4] mt-3 rounded-md p-2">
              <Table />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
