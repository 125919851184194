import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
const VouchersModal = ({ setShow, onSubmit, note, setNote }) => {
  console.log("onSubmit function:", onSubmit);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const router = useNavigate();

  const user = useSelector((state) => state.user);

  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center `}
    >
      <div className="relative p-4 w-full max-w-lg max-h-full">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center  p-4 md:p-3 border-b rounded-t ">
            <h3 className="text-lg font-semibold text-red-600 text-center ">
              Rejection Reason
            </h3>
            <button
              onClick={() => setShow(false)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
              data-modal-toggle="select-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="px-4 py-6">
            <p className="mb-4 text-center">
              You are about to reject this order. Please provide a note below to
              guide the employee on making the necessary changes and
              resubmitting it for approval.
            </p>
            <textarea
              rows="4"
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary focus:border-primary "
              placeholder="Type Note Here"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            ></textarea>

            <div className="flex gap-2 p-4">
              <button
                onClick={() => {
                  setShow(false);
                }}
                className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-red-600  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onSubmit();
                }}
                className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />} */}
    </div>
  );
};

export default VouchersModal;
