import back5 from "../../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import Nav from "../../navbar";
import Main from "./main";

const Listing = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const QuotationFlow = useSelector((state) => state.QuotationFlow);
  console.log(QuotationFlow);

  return (
    <>
      <div>
        <Nav />
        <div className="flex items-center justify-center bg-[#D9EFED] ">
          <div className="w-[95%] mt-[1rem] px-10 rounded-md mb-12">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (QuotationFlow) {
                    router("/Inventory/Quotations");
                    return;
                  }
                  if (user === "vendor-owner" && !QuotationFlow) {
                    router("/Inventory-Vendor-Orders");
                    return;
                  }

                  router("/Inventory-Vendor-Orders");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                {QuotationFlow ? "Quotation Detail" : "Order Detail"}
              </h2>
            </div>

            <Main />
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
