import React from "react";
import { Avatar, Collapse } from "antd";
import Address from "../headquater/address";
import MailAddress from "../headquater/mailaddress";
import Email from "../headquater/email";
import Number from "../headquater/number";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const { Panel } = Collapse;

const Store = ({ title, data }) => {
  const user = useSelector((state) => state.user);
  const VendorAddress = data?.vendoraddresses?.[0];
  const callback = (key) => {
    console.log(key);
  };
  const location = useLocation();
  const dash = location.pathname;
  return (
    <div className="">
      <div className="sm:p-6 p-2 bg-[#F4F5FE] rounded-lg  mb-8">
        <div className="flex items-center">
          <Avatar
            className="sm:w-24 sm:h-24 w-16 h-16 rounded-full mr-4 border-2 border-primary"
            src={user === "vendor-owner" || dash === "/VendorDashboard/profile" ? data?.logo : data?.image}
            alt="Profile"
          />
          <div className="text-sm">
            <h4 className="sm:text-xl text-md font-semibold text-primary leading-none mb-2">
              {title}
            </h4>
            {/* <p className="text-md text-gray-600 font-medium">Since: 2023</p> */}
            {/* <p className="text-md text-gray-600 font-medium">
                Mon-Fri, 9:00 AM - 5:00 PM
              </p> */}
          </div>
        </div>
        <div className="sm:p-8 sm:mt-0 mt-6">
          <Collapse
            // defaultActiveKey={["1"]}
            accordion={true}
            onChange={callback}
            style={{ backgroundColor: "white" }}
          >
            {/* <Panel
                            header="Contact Details"
                            key="1"
                            className="p-3 text-md text-gray-800 font-medium"
                        >
                            <Contact headquarter={data} type={"Store"}/>
                        </Panel> */}
            <Panel
              header="Emails"
              key="1"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Email headquarter={data} type={"Store"} />
            </Panel>
            <Panel
              header="Contacts"
              key="2"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Number headquarter={data} type={"Store"} />
            </Panel>
            <Panel
              header="Address"
              key="3"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Address
                headquarter={user === "vendor-owner" || dash === "/VendorDashboard/profile" ? VendorAddress : data}
                title={"Address"}
              />
            </Panel>
            {user === "vendor-owner" || dash === "/VendorDashboard/profile" ? null : (
              <Panel
                header="Mail Address"
                key="4"
                className="p-3 text-md text-gray-800 font-medium"
              >
                <MailAddress
                  headquarter={data?.merchstoremailaddresses?.[0]}
                  title={"Store MailAddress"}
                />
              </Panel>
            )}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Store;
