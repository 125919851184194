import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import done from "../../../../imgs/optionBranch.svg";
const SuccessModal = ({ setShow }) => {
  const PlaceOrderCheck = useSelector((state) => state.PlaceOrderCheck);
  const router = useNavigate();
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div className="relative rounded-xl w-[25rem] flex items-center p-6 bg-white flex-col">
        <img src={done} alt="done" className="w-20 h-20" />

        <p className="text-center  mb-4 mt-4 text-[1rem]  px-6">
          We have multiple branches for this company.Do you want to place order
          with company's headquarters or with one of their branches?
        </p>
        {/* <p className="text-center  mb-5 text-[1rem]  px-6">
          Select Yes to proceed with headquarters or No to place it with one of
          their branches.
        </p> */}
        <div className="flex items-center justify-center gap-2">
          <button
            onClick={() => {
              setShow(false);
              router("/SuperAdmin/Inventory/SelectBranch");
            }}
            className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] text-center text-white bg-red-600 w-[9rem]"
          >
            BRANCHES
          </button>
          <button
            onClick={() => {
              setShow(false);
              router("/Inventory-Products");
            }}
            className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem]  text-white bg-primary w-[9rem]"
          >
            COMPANY HQ
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
