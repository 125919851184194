import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Nav from "../../vendor/navbar";

import Table from "./table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
const Listing = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SELECTED_COMPANY_VENDOR", num: null });
    dispatch({ type: "SELECTED_BRANCH_VENDOR", num: null });


  }, []);

  const vendorData = [
    {
      srNo: 1,
      vendorName: "John's Supplies",
      phone: "123-456-7890",
      email: "john@supplies.com",
      address: "123 Market St, New York, NY",
      totalOrders: 150,
      registeredDate: "4/12/2022",
      registeredTime: "06:20pm",
    },
    {
      srNo: 2,
      vendorName: "Smith Tools",
      phone: "987-654-3210",
      email: "smith@tools.com",
      address: "456 Tool Ave, Los Angeles, CA",
      totalOrders: 98,
      registeredDate: "10/23/2021",
      registeredTime: "06:20pm",
    },
    {
      srNo: 3,
      vendorName: "Baker Essentials",
      phone: "456-123-7890",
      email: "info@bakeressentials.com",
      address: "789 Bakery Rd, Chicago, IL",
      totalOrders: 203,
      registeredDate: "1/18/2023",
      registeredTime: "06:20pm",
    },
    {
      srNo: 4,
      vendorName: "FastTech Solutions",
      phone: "321-654-9870",
      email: "support@fasttech.com",
      address: "321 Innovation Blvd, San Francisco, CA",
      totalOrders: 75,
      registeredDate: "8/5/2020",
      registeredTime: "06:20pm",
    },
    {
      srNo: 5,
      vendorName: "Green Grocers",
      phone: "789-321-6540",
      email: "sales@greengrocers.com",
      address: "654 Green Valley St, Miami, FL",
      totalOrders: 120,
      registeredDate: "12/30/2022",
      registeredTime: "06:20pm",
    },
  ];

  return (
    <div>
      <Nav />
      <div className="flex items-center justify-center bg-[#D9EFED] ">
        <div className="w-[95%] mt-[1rem] px-10 rounded-md mb-12">
          <div className="cursor-pointer flex w-full items-center justify-center">
            <img
              onClick={() => {
                if (user === "vendor-owner") {
                  router("/VendorDashboard");
                  return;
                }
                router("/SuperAdmin/InventoryDashboard");
              }}
              className="sm:w-[1.7rem] w-[1.3rem]"
              src={back5}
              alt=""
            />
            <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
              Vendors
            </h2>
          </div>
          {user === "superadmin" && (
            <div className="flex justify-end mt-4 gap-3">
              <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-2 w-[300px] shadow-sm">
                <input
                  type="text"
                  placeholder="Search Vendor"
                  className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#1BA397]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.8"
                    d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                  />
                </svg>
              </div>
              <button
                type="button"
                onClick={() => {
                  router("/SuperAdmin/Inventory/AddVendor");
                }}
                className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
              >
                Add Vendor
              </button>
            </div>
          )}

          <div className="bg-[#F6F4F4] mt-3 rounded-md p-6">
            {/* <div className="py-2 px-2">
              <div className="flex items-center justify-between">
                <h1 className="text-gray-700 font-semibold text-[1.1rem] mt-2">
                  Recent Vendors
                </h1>
              </div>
              <div className="bg-white mt-2 p-4 px-6 rounded-sm w-full grid grid-cols-5  justify-center items-center gap-8 md:gap-x-4 gap-y-10 sm:min-h-[200px]">
                {vendorData?.map((item, index) => (
                  <div className=" bg-white border border-gray-200 rounded-lg shadow">
                    <img
                      className="rounded-t-lg w-full h-24 object-cover"
                      src="https://static.vecteezy.com/system/resources/thumbnails/007/209/020/small_2x/close-up-shot-of-happy-dark-skinned-afro-american-woman-laughs-positively-being-in-good-mood-dressed-in-black-casual-clothes-isolated-on-grey-background-human-emotions-and-feeligs-concept-photo.jpg"
                      alt=""
                    />

                    <div className="p-2">
                      <div className="flex items-center justify-between">
                        <h5 className="mb-2 text-[0.9rem] font-semibold tracking-tight text-gray-700 ">
                          {item?.vendorName}
                        </h5>
                      </div>
                      <div className="flex items-center justify-between">
                        <p>Total Orders</p>
                        <div className="inline-flex items-center px-8 py-1 text-sm font-medium text-center text-white bg-primary rounded-lg ">
                          120
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}

            <Table search={search} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
