export const vendorStatus = (state = false, action) => {
  switch (action.type) {
    case "VENDOR_STATUS":
      return action.num;
    default:
      return state;
  }
};

export const category = (state = "", action) => {
  switch (action.type) {
    case "Cat":
      return action.num;
    default:
      return state;
  }
};
export const role = (state = "", action) => {
  switch (action.type) {
    case "Role":
      return action.num;
    default:
      return state;
  }
};


export const cartData = (state = [], action) => {
  switch (action.type) {
    case "Cart":
      return action.num;
    default:
      return state;
  }
};
export const favData = (state = [], action) => {
  switch (action.type) {
    case "Fav":
      return action.num;
    default:
      return state;
  }
};
export const productData = (state = [], action) => {
  switch (action.type) {
    case "Product":
      return action.num;
    default:
      return state;
  }
};

export const PlaceOrderCheck = (state = false, action) => {
  switch (action.type) {
    case "PLACE_ORDER_CHECK":
      return action.num;
    default:
      return state;
  }
};

export const StorageType = (state = null, action) => {
  switch (action.type) {
    case "STORAGE_TYPE":
      return action.num;
    default:
      return state;
  }
};

export const ProductId = (state = null, action) => {
  switch (action.type) {
    case "PRODUCT_ID":
      return action.num;
    default:
      return state;
  }
};

export const VendorBranches = (state = [], action) => {
  switch (action.type) {
    case "VENDOR_BRANCHES":
      return action.num;
    default:
      return state;
  }
};

export const editProduct = (state = false, action) => {
  switch (action.type) {
    case "EDIT_PRODUCT":
      return action.num;
    default:
      return state;
  }
};

export const Edit = (state = false, action) => {
  switch (action.type) {
    case "EDIT":
      return action.num;
    default:
      return state;
  }
};

export const Data = (state = null, action) => {
  switch (action.type) {
    case "DATA":
      return action.num;
    default:
      return state;
  }
};

//Selected company of Vendor
export const SelectedCompanyVendor = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_COMPANY_VENDOR":
      return action.num;
    default:
      return state;
  }
};

//Selected Branch of Vendor
export const SelectedBranchVendor = (state = null, action) => {
  switch (action.type) {
    case "SELECTED_BRANCH_VENDOR":
      return action.num;
    default:
      return state;
  }
};

//Selected Branch of Vendor
export const PlaceOrderData = (state = {}, action) => {
  switch (action.type) {
    case "PLACE_ORDER_DATA":
      return action.num;
    default:
      return state;
  }
};


//Selected Branch of Vendor
export const OrderData = (state = {}, action) => {
  switch (action.type) {
    case "ORDER_DATA":
      return action.num;
    default:
      return state;
  }
};


export const ProductTaxes = (state = [], action) => {
  switch (action.type) {
    case "PRODUCT_TAXES":
      return action.num;
    default:
      return state;
  }
};

export const QuotationFlow = (state = false, action) => {
  switch (action.type) {
    case "QUOTATION_FLOW":
      return action.num;
    default:
      return state;
  }
};

export const SelectedVendors = (state = [], action) => {
  switch (action.type) {
    case "SELECTED_VENDORS":
      return action.num;
    default:
      return state;
  }
};


export const NewOrderData = (state = {}, action) => {
  switch (action.type) {
    case "NEW_ORDER_DATA":
      return action.num;
    default:
      return state;
  }
};

export const SelectedPaymentTerm =(state = null, action) => {
  switch (action.type) {
    case "SELECTED_PAYMENT_TERM":
      return action.num;
    default:
      return state;
  }
};

export const PsOrders =(state = null, action) => {
  switch (action.type) {
    case "PS_ORDERS":
      return action.num;
    default:
      return state;
  }
};


export const SelectedVendorData =(state = null, action) => {
  switch (action.type) {
    case "SELECTED_VENDOR_DATA":
      return action.num;
    default:
      return state;
  }
};

