import { Select, DatePicker, Checkbox, Switch, Grid } from "antd";
import { useRef, useState } from "react";
import check from "../../../imgs/add.png";
import trash from "../../../imgs/trash.png";
import back5 from "../../../imgs/back5.png";
import discountHis from "../../../imgs/discountHis.svg";
import {
  PlusCircleFilled,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ErrorModal from "../../Merchant/Modals/errorModal";
import AddTierModal from "./tierPricing/addTierModal";
import SuccessModal from "../../Merchant/Modals/successModal";
import dayjs from "dayjs";
let file;
const Mid = () => {
  const [refetch, setRefetch] = useState(false);
  const productData = useSelector((state) => state.product);
  const ProductId = useSelector((state) => state.ProductId);
  const editProduct = useSelector((state) => state.editProduct);
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const token = useSelector((state) => state.token);
  const [display, setDisplay] = useState("");
  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const fileInputRef = useRef(null);
  const [err, setErr] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddTier, setShowAddTier] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [discountCall, setDiscountCall] = useState(false);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [sku, setSku] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [stock, setStock] = useState("");
  const [warranty, setWarranty] = useState(null);
  const [cost, setCost] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [pricingUnit, setPricingUnit] = useState(null);
  const [pricingType, setPricingType] = useState(null);
  const [expiryDate, setExpiryDate] = useState("");
  const [lowLimit, setLowLimit] = useState(null);
  const [reorderQuantity, setReorderQuantity] = useState(null);
  const [description, setDescription] = useState("");
  const [barCode, setBarCode] = useState("");
  const [returnable, setReturnable] = useState(false);
  const [returnableDays, setReturnableDays] = useState(null);
  const [exchangable, setExchangable] = useState(false);
  const [exchangableDays, setExchangableDays] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [branches, setBranches] = useState([]);
  const [branchId, setBranchId] = useState(null);
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [selectedOption, setSelectedOption] = useState("tier");

  const [amountOption, setAmountOption] = useState("tier");

  const [minimumOrderQty, setMinimumOrderQty] = useState(null);
  const [refetchTaxes, setRefetchTaxes] = useState(false);
  // Location object state
  const [location, setLocation] = useState({
    store: "",
    aisle: "",
    rack: "",
    shelf: "",
    bin: "",
  });

  const [productStoreType, setProductStoreType] = useState(null);

  const [activeLocation, setActiveLocation] = useState(true);
  const [activeDiscount, setActiveDiscount] = useState(false);

  //Discount
  const [discountName, setDiscountName] = useState("");
  const [discountDescription, setDiscountDescription] = useState("");
  const [discountType, setDiscountType] = useState("percent");
  const [discountValue, setDiscountValue] = useState("");
  const [discountStartDate, setDiscountStartDate] = useState("");
  const [discountEndDate, setDiscountEndDate] = useState("");
  const [taxable, setTaxable] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [defaultTaxes, setDefaultTaxes] = useState([]);
  const [additionalTaxes, setAdditionalTaxes] = useState([]);

  const [freeDeliveryCondition, setFreeDeliveryCondition] = useState(null);
  const [freeDeliveryConditionValue, setFreeDeliveryConditionValue] =
    useState(null);
  const [deliveryFeePerUnit, setDeliveryFeePerUnit] = useState("");

  console.log(taxes, "taxes");
  useEffect(() => {
    if (editProduct) {
      setName(productData?.name);
      setSku(productData?.sku);
      setItemCode(productData?.itemCode);
      setStock(productData?.stock);
      setWarranty(productData?.warranty);
      setCost(productData?.cost);
      setBasePrice(productData?.basePrice);
      setPricingUnit(productData?.pricingUnit);
      setPricingType(productData?.pricingType);
      setExpiryDate(productData?.expiryDate);
      setLowLimit(productData?.lowLimit);
      // setReorderQuantity(productData?.reorderQuantity);
      setDescription(productData?.description);
      setBarCode(productData?.barCode);
      setReturnable(productData?.returnable);
      setReturnableDays(productData?.returnableDays);
      setExchangable(productData?.exchangable);
      setExchangableDays(productData?.exchangableDays);
      // setImageUrl(productData?.invproductgallaries);
      setMinimumOrderQty(productData?.minimumOrderQty);
      setPricingUnit(productData?.pricingUnit);
      setPricingType(productData?.pricingType);
      setCategoryId(productData?.invcategories?.[0]?.id);
      setProductStoreType(productData?.productStoreType);
      setLocation({
        store: productData?.invproductlocation?.store
          ? productData?.invproductlocation?.store
          : "",
        aisle: productData?.invproductlocation?.aisle
          ? productData?.invproductlocation?.aisle
          : "",
        rack: productData?.invproductlocation?.rack
          ? productData?.invproductlocation?.rack
          : "",
        shelf: productData?.invproductlocation?.shelf
          ? productData?.invproductlocation?.shelf
          : "",
        bin: productData?.invproductlocation?.bin
          ? productData?.invproductlocation?.bin
          : "",
      });
      setBranchId(productData?.vendorbranchId);
      setDeliveryFeePerUnit(productData?.deliveryFeePerUnit);

      if (
        productData?.freeDeliveryCondition !== null ||
        productData?.freeDeliveryCondition !== "" ||
        productData?.freeDeliveryCondition !== undefined
      ) {
        setFreeDelivery(true);
        setFreeDeliveryCondition(productData?.freeDeliveryCondition);
        setFreeDeliveryConditionValue(productData?.freeDeliveryConditionValue);
      }
    }
  }, []);

  //get Categories
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-category/list-for-vendor`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        let wow = [];

        data.data.rows.map((item, index) => {
          wow.push({
            value: item?.id,
            label: item?.name,
          });
        });
        setCategories(wow);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  }, []);

  //get Product Images
  useEffect(() => {
    if (editProduct) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/inv-product/${ProductId}/product-images`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setImageUrl(data.data);
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShowErrorModal(true);
        });
    }
  }, [refetch]);

  //get Branches
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/vendor-branch/own`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        let wow = [];
        data?.data?.rows?.map((item, index) => {
          wow.push({
            value: item?.id,
            label: item?.name,
          });
        });
        setBranches(wow);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  }, []);

  //get Taxes
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-global-tax/list-for-vendor`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        let defaultTaxesArray = [];
        let additionalTaxesArray = [];
        data?.data?.rows?.forEach((item) => {
          const tax = { value: item?.id, label: item?.name };
          if (item?.type === "default") {
            defaultTaxesArray.push(tax);
          } else if (item?.type === "additional") {
            additionalTaxesArray.push(tax);
          }
        });
        setDefaultTaxes(defaultTaxesArray);
        setAdditionalTaxes(additionalTaxesArray);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  }, []);

  //get Taxes
  useEffect(() => {
    if (!editProduct) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-product/${ProductId}/get-product-taxes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        if (data?.data?.length > 0) {
          setTaxable(true);
        }
        const addedTaxes = data?.data?.map((item) => item?.invglobaltaxId);

        setTaxes(addedTaxes || []); // Update taxes state
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  }, [refetchTaxes]);

  const DeleteImage = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-product/product-image?invProductGallaryId=${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        // setErr(`Tier Price Deleted Successfully`);
        // setShowSuccessModal(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current < disabledBeforeDate1;
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  // const AddProduct = () => {
  //   if (name === "") {
  //     setErr("Name is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (sku === "") {
  //     setErr("SKU is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (itemCode === "") {
  //     setErr("Item Code is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (stock === "") {
  //     setErr("Stock is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (warranty === null) {
  //     setErr("Warranty is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (cost === "") {
  //     setErr("Cost is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (basePrice === "") {
  //     setErr("Selling Price is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (expiryDate === "") {
  //     setErr("Expiry Date is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   // if (reorderQuantity === null || reorderQuantity === "") {
  //   //   setErr("Reorder Quantity is required");
  //   //   setShowErrorModal(true);
  //   //   return;
  //   // }
  //   if (pricingUnit === "") {
  //     setErr("Unit is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (pricingType === null) {
  //     setErr("Pricing Type is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (barCode === "") {
  //     setErr("BarCode Image is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (description === "") {
  //     setErr("Description is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (categoryId === null) {
  //     setErr("Category is required");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (productStoreType === null) {
  //     setErr("Please Select product Store Type.");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   if (productStoreType === "branch" && branchId === null) {
  //     setErr("Please Select Branch Type.");
  //     setShowErrorModal(true);
  //     return;
  //   }
  //   const body = {
  //     name,
  //     // image,
  //     sku,
  //     itemCode,
  //     stock,
  //     warranty,
  //     cost,
  //     basePrice,
  //     pricingType,
  //     expiryDate,
  //     lowLimit,
  //     // reorderQuantity,
  //     description,
  //     barCode,
  //     location,
  //     productStoreType: "company",
  //     pricingUnit,
  //     invProductGallary: imageUrl,
  //     minimumOrderQty,
  //   };

  //   if (
  //     // location.store === "" &&
  //     location.aisle === "" &&
  //     location.rack === "" &&
  //     location.shelf === "" &&
  //     location.bin === ""
  //   ) {
  //     delete body.location;
  //   }

  //   if (returnable === true) {
  //     body.returnable = true;
  //     body.returnableDays = returnableDays;
  //   }

  //   if (exchangable === true) {
  //     body.exchangable = true;
  //     body.exchangableDays = exchangableDays;
  //   }

  //   if (lowLimit === null) {
  //     delete body.lowLimit;
  //   }

  //   if (productStoreType === "branch") {
  //     body.productStoreType = "branch";
  //     body.vendorbranchId = branchId;
  //   }

  //   if (
  //     (discountName !== "" ||
  //       discountValue !== "" ||
  //       discountStartDate !== "" ||
  //       discountEndDate !== "" ||
  //       discountDescription !== "") &&
  //     editProduct === false
  //   ) {
  //     if (discountName === "") {
  //       setErr("Discount name is required");
  //       setShowErrorModal(true);
  //       return;
  //     }
  //     if (discountValue === "") {
  //       setErr("Discount value is required");
  //       setShowErrorModal(true);
  //       return;
  //     }
  //     if (discountStartDate === "") {
  //       setErr("Discount start date is required");
  //       setShowErrorModal(true);
  //       return;
  //     }
  //     if (discountEndDate === "") {
  //       setErr("Discount end date is required");
  //       setShowErrorModal(true);
  //       return;
  //     }
  //     if (discountDescription === "") {
  //       setErr("Discount description is required");
  //       setShowErrorModal(true);
  //       return;
  //     }
  //     setDiscountCall(true);
  //   }

  //   if (editProduct) {
  //     delete body.productStoreType;
  //     delete body.vendorbranchId;
  //     delete body.invProductGallary;
  //   }

  //   const api = editProduct
  //     ? `v1/inv-product/${ProductId}`
  //     : `v1/inv-product?invCategoryId=${categoryId}`;

  //   axios({
  //     method: editProduct ? "put" : "post",
  //     url: `${process.env.REACT_APP_BASE_URL}/${api}`,
  //     data: body,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((data) => {
  //       if (editProduct) {
  //         setErr("Product Updated Successfully");
  //         setShowSuccessModal(true);
  //         router("/Inventory");
  //         return;
  //       }
  //       dispatch({ type: "PRODUCT_ID", num: data.data.id });
  //       if (
  //         discountName !== "" &&
  //         discountValue !== "" &&
  //         discountStartDate !== "" &&
  //         discountEndDate !== "" &&
  //         discountDescription !== ""
  //       ) {
  //         AddDiscount(data?.data?.id);
  //         return;
  //       }
  //       // setShowAddTier(true);
  //       router("/Inventory/AddProduct/AddTier");
  //     })
  //     .catch((err) => {
  //       setErr(err.response.data.message);
  //       setShowErrorModal(true);
  //     });
  // };

  const AddProduct = () => {
    if (name === "") {
      setErr("Name is required");
      setShowErrorModal(true);
      return;
    }
    // if (sku === "") {
    //   setErr("SKU is required");
    //   setShowErrorModal(true);
    //   return;
    // }
    if (itemCode === "") {
      setErr("Item Code is required");
      setShowErrorModal(true);
      return;
    }
    if (stock === "") {
      setErr("Stock is required");
      setShowErrorModal(true);
      return;
    }
    if (warranty === null) {
      setErr("Warranty is required");
      setShowErrorModal(true);
      return;
    }
    if (cost === "") {
      setErr("Cost is required");
      setShowErrorModal(true);
      return;
    }
    if (basePrice === "") {
      setErr("Selling Price is required");
      setShowErrorModal(true);
      return;
    }
    if (expiryDate === "") {
      setErr("Expiry Date is required");
      setShowErrorModal(true);
      return;
    }
    if (deliveryFeePerUnit === null || deliveryFeePerUnit === "") {
      setErr("Delivery Fee per Unit is required");
      setShowErrorModal(true);
      return;
    }
    if (pricingUnit === "") {
      setErr("Unit is required");
      setShowErrorModal(true);
      return;
    }
    if (pricingType === null) {
      setErr("Pricing Type is required");
      setShowErrorModal(true);
      return;
    }
    if (barCode === "") {
      setErr("BarCode Image is required");
      setShowErrorModal(true);
      return;
    }
    if (description === "") {
      setErr("Description is required");
      setShowErrorModal(true);
      return;
    }
    if (categoryId === null) {
      setErr("Category is required");
      setShowErrorModal(true);
      return;
    }
    if (productStoreType === null) {
      setErr("Please Select product Store Type.");
      setShowErrorModal(true);
      return;
    }
    if (productStoreType === "branch" && branchId === null) {
      setErr("Please Select Branch Type.");
      setShowErrorModal(true);
      return;
    }

    if (
      (discountName !== "" ||
        discountValue !== "" ||
        discountStartDate !== "" ||
        discountEndDate !== "" ||
        discountDescription !== "") &&
      editProduct === false
    ) {
      if (discountName === "") {
        setErr("Discount name is required");
        setShowErrorModal(true);
        return;
      }
      if (discountValue === "") {
        setErr("Discount value is required");
        setShowErrorModal(true);
        return;
      }
      if (discountStartDate === "") {
        setErr("Discount start date is required");
        setShowErrorModal(true);
        return;
      }
      if (discountEndDate === "") {
        setErr("Discount end date is required");
        setShowErrorModal(true);
        return;
      }
      if (discountDescription === "") {
        setErr("Discount description is required");
        setShowErrorModal(true);
        return;
      }
    }

    if (taxable === true && editProduct === false) {
      if (taxes.length === 0) {
        setErr("Please select at least one tax");
        setShowErrorModal(true);
        return;
      }
    }

    if (freeDelivery) {
      if (freeDeliveryCondition === null) {
        setErr("Minimum Quantity type Required for Free Delivery");
        setShowErrorModal(true);
        return;
      }
      if (freeDeliveryConditionValue === null) {
        setErr("Minimum Quantity value Required for Free Delivery");
        setShowErrorModal(true);
        return;
      }
    }

    const body = {
      name,
      sku,
      itemCode,
      stock,
      warranty,
      cost,
      basePrice,
      pricingType,
      expiryDate,
      lowLimit,
      description,
      barCode,
      location,
      productStoreType: "company",
      pricingUnit,
      invProductGallary: imageUrl,
      minimumOrderQty,
      deliveryFeePerUnit,
    };

    // Clean up optional fields
    if (
      location.aisle === "" &&
      location.rack === "" &&
      location.shelf === "" &&
      location.bin === ""
    ) {
      delete body.location;
    }

    if (freeDelivery) {
      body.freeDeliveryCondition = freeDeliveryCondition;
      body.freeDeliveryConditionValue = freeDeliveryConditionValue;
    }

    if (returnable) {
      body.returnable = true;
      body.returnableDays = returnableDays;
    }
    if (exchangable) {
      body.exchangable = true;
      body.exchangableDays = exchangableDays;
    }
    if (lowLimit === null) {
      delete body.lowLimit;
    }
    if (productStoreType === "branch") {
      body.productStoreType = "branch";
      body.vendorbranchId = branchId;
    }

    // Handle edit product scenario
    if (editProduct) {
      delete body.productStoreType;
      delete body.vendorbranchId;
      delete body.invProductGallary;
    }

    const api = editProduct
      ? `v1/inv-product/${ProductId}`
      : `v1/inv-product?invCategoryId=${categoryId}`;

    // API call to add or edit product
    axios({
      method: editProduct ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/${api}`,
      data: body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        const productId = data?.data?.invProduct?.id;
        if (editProduct) {
          AddTaxes(ProductId);
          // setErr("Product Updated Successfully");
          // setShowSuccessModal(true);
          // router("/Inventory");
          return;
        }

        dispatch({ type: "PRODUCT_ID", num: productId });

        // Handle Discounts
        const hasDiscount =
          discountName !== "" &&
          discountValue !== "" &&
          discountStartDate !== "" &&
          discountEndDate !== "" &&
          discountDescription !== "";

        const addDiscountPromise = hasDiscount
          ? AddDiscount(productId)
          : Promise.resolve();

        // Handle Taxes
        const addTaxesPromise =
          taxable === true && taxes.length > 0
            ? AddTaxes(productId)
            : Promise.resolve();

        // Wait for both calls (if applicable) before navigating
        Promise.all([addDiscountPromise, addTaxesPromise])
          .then(() => {
            // alert("Product Added Successfully");
            router("/Inventory/AddProduct/AddTier");
          })
          .catch((error) => {
            setErr(error.response?.data?.message || "An error occurred");
            setShowErrorModal(true);
          });
      })
      .catch((err) => {
        setErr(err.response?.data?.message || "An error occurred");
        setShowErrorModal(true);
      });
  };

  const AddDiscount = (id) => {
    const requestBody = {
      name: discountName,
      description: discountDescription,
      type: discountType,
      value: discountValue,
      startDate: discountStartDate,
      endDate: discountEndDate,
    };

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/inv-product/${id}/add-discount`,
      data: requestBody,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        // setShowAddTier(true);
        setDiscountName("");
        setDiscountDescription("");
        setDiscountType("");
        setDiscountValue("");
        setDiscountStartDate("");
        setDiscountEndDate("");

        if (editProduct) {
          setErr("Discount Added Successfully");
          setShowSuccessModal(true);
          return;
        }
        router("/Inventory/AddProduct/AddTier");
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  };

  const AddTaxes = (id) => {
    const requestBody = {
      invglobaltaxIDs: taxes,
    };

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/inv-product/${id}/update-product-taxes`,
      data: requestBody,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        if (editProduct) {
          setErr("Product Updated Successfully");
          setShowSuccessModal(true);
          // router("/Inventory");
          return;
        }
        // alert("Tax Added");
        router("/Inventory/AddProduct/AddTier");
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  };

  const imageHandlerE = async (e, type) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    // setImage(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/vendor/images?fileType=productImage`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        if (type === "barCode") {
          setBarCode(data.data.url);
          return;
        }
        if (type === "coverPhoto") {
          if (editProduct) {
            UpdateGallery(ProductId, {
              image: data.data.url,
              isCoverImg: true,
            });
            return;
          }
          setImageUrl([
            ...imageUrl,
            { image: data.data.url, isCoverImg: true },
          ]);
        } else {
          if (editProduct) {
            UpdateGallery(ProductId, {
              image: data.data.url,
              isCoverImg: false,
            });
            return;
          }

          setImageUrl([
            ...imageUrl,
            { image: data.data.url, isCoverImg: false },
          ]);
        }

        setImages([...images, base641]);
      })
      .catch(() => {});
  };

  const UpdateGallery = (id, image) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-product/${id}/upload-images`,
        [image],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrorModal(true);
      });
  };

  return (
    <>
      <div>
        <div
          className={`${
            user === "vendor-owner"
              ? "flex items-center justify-center bg-[#D9EFED] min-h-screen"
              : "flex items-center justify-center"
          }`}
        >
          <div className="md:w-[95%] mt-[1rem] rounded-md w-[95%] px-20">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => router("/Inventory")}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                {editProduct ? "Product Detail" : "Add Product"}
              </h2>
            </div>

            <div className="mt-6  rounded-md  p-[4rem] pt-[2rem] mb-8  sm:min-h-[360px] bg-white shadow-lg">
              <form
                action=""
                className="mt-10 sm:mt-[3rem] mb-[5rem] "
                onSubmit={""}
              >
                {/* <div className="flex items-center justify-center mb-4 flex-col">
                  <label
                    htmlFor="special-input"
                    className="cursor-pointer relative"
                  >
                    <div className="relative w-[18rem] h-[10rem] overflow-hidden border-2 border-[#686262] bg-gray-200  rounded-md mb-4 ">
                      <img
                        className={`w-full h-full ${
                          !imageUrl?.find((img) => img.isCoverImg)
                            ? "hidden"
                            : ""
                        }`}
                        src={
                          imageUrl?.find((img) => img.isCoverImg)?.image || ""
                        }
                        alt=""
                      />
                    </div>

                   

                    <div
                      className={`${
                        imageUrl?.find((img) => img.isCoverImg)?.image
                          ? "absolute bottom-[3px] right-[46%]"
                          : "bg-primary rounded-full absolute bottom-[3px] right-[46%]"
                      } `}
                    >
                      <img
                        src={
                          imageUrl?.find((img) => img.isCoverImg)?.image
                            ? trash
                            : check
                        }
                        className="lg:w-7 w-5 cursor-pointer"
                        alt=""
                        onClick={() => {
                          const coverImage = imageUrl?.find(
                            (img) => img.isCoverImg
                          );
                          if (editProduct) {
                            if (coverImage) {
                              DeleteImage(coverImage.id);
                            }
                          } else {
                            setImageUrl(
                              imageUrl.filter(
                                (img) => img !== coverImage?.image
                              )
                            );
                          }
                        }}
                      />
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      id="special-input"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {
                        if (editProduct) {
                          const coverImage = imageUrl?.find(
                            (img) => img.isCoverImg
                          );
                          if (coverImage) {
                            DeleteImage(coverImage.id);
                          }
                          imageHandlerE(e, "coverPhoto");
                        } else {
                          imageHandlerE(e, "coverPhoto");
                        }
                      }}
                      ref={fileInputRef}
                    />
                  </label>
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Product cover page
                  </h2>
                </div> */}

                <div className="flex items-center justify-center mb-4 flex-col">
                  {imageUrl?.find((img) => img.isCoverImg) ? (
                    // Display the uploaded image with delete icon
                    <div className="relative w-[18rem] h-[10rem] overflow-hidden border-2 border-[#686262] bg-gray-200 rounded-md mb-4">
                      <img
                        className="w-full h-full"
                        src={
                          imageUrl.find((img) => img.isCoverImg)?.image || ""
                        }
                        alt="Uploaded cover"
                      />

                      <div
                        className="absolute  bottom-[-3px] right-[46%] cursor-pointer z-10"
                        onClick={() => {
                          const coverImage = imageUrl.find(
                            (img) => img.isCoverImg
                          );
                          if (editProduct && coverImage) {
                            DeleteImage(coverImage.id);
                          }
                          setImageUrl(
                            imageUrl.filter((img) => img !== coverImage)
                          );
                        }}
                      >
                        <img src={trash} className="w-9" alt="Delete icon" />
                      </div>
                    </div>
                  ) : (
                    // Show the label and file input if no image is uploaded
                    <label
                      htmlFor="special-input"
                      className="cursor-pointer relative"
                    >
                      <div className="relative w-[18rem] h-[10rem] overflow-hidden border-2 border-[#686262] bg-gray-200 rounded-md mb-4 flex items-center justify-center">
                        <span className="text-gray-500">
                          Upload Cover Image
                        </span>
                      </div>
                      <div
                        className={`${
                          imageUrl?.find((img) => img.isCoverImg)?.image
                            ? "absolute bottom-[3px] right-[46%]"
                            : "bg-primary rounded-full absolute bottom-[3px] right-[46%]"
                        } `}
                      >
                        <img src={check} className="lg:w-7 w-5" alt="" />
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        id="special-input"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          if (editProduct) {
                            imageHandlerE(e, "coverPhoto");
                          } else {
                            imageHandlerE(e, "coverPhoto");
                          }
                        }}
                        ref={fileInputRef}
                      />
                    </label>
                  )}

                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Product Cover Image
                  </h2>
                </div>

                <div className="flex justify-center flex-col items-center">
                  <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[85%] w-[80%]">
                    <div className="w-[100%]">
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Product Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder=" Product Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          SKU <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="SKU"
                          value={sku}
                          onChange={(e) => setSku(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Item Code <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Item Code"
                          value={itemCode}
                          onChange={(e) => setItemCode(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>

                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Stock <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Stock "
                          value={stock}
                          onChange={(e) => setStock(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Category <span className="text-red-600">*</span>
                        </label>
                        <Select
                          placeholder="Category"
                          optionFilterProp="children"
                          className="wow"
                          style={{ marginBottom: "1rem" }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          options={categories}
                          onChange={(e) => setCategoryId(e)}
                          value={categoryId}
                        />
                      </div>

                      <div className="flex items-end justify-between bg-[#F6F4F4] p-4 rounded-md">
                        <h1 className="text-gray-600 text-[0.95rem] font-semibold">
                          Location
                        </h1>
                        <p
                          className="text-[#596F96] text-[0.88rem] font-semibold cursor-pointer"
                          onClick={() => {
                            setActiveLocation(!activeLocation);
                          }}
                        >
                          {activeLocation ? (
                            <RightOutlined />
                          ) : (
                            <DownOutlined />
                          )}
                        </p>
                      </div>

                      <div
                        className={`${
                          activeLocation ? "flex flex-col" : "hidden"
                        }`}
                      >
                        <div className="bg-white p-4 rounded-md">
                          <div className="flex flex-col">
                            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                              Product stored at
                              <span className="text-red-600"></span>
                            </label>
                            <Select
                              placeholder="Store Type"
                              optionFilterProp="children"
                              className="wow"
                              style={{ marginBottom: "1rem" }}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              size="middle"
                              onChange={(value) => setProductStoreType(value)}
                              value={productStoreType}
                              options={
                                branches?.length > 0
                                  ? [
                                      {
                                        value: "company",
                                        label: "Company",
                                      },
                                      {
                                        value: "branch",
                                        label: "Branch",
                                      },
                                    ]
                                  : [
                                      {
                                        value: "company",
                                        label: "Company",
                                      },
                                    ]
                              }
                            />
                          </div>
                          <div
                            className={`${
                              productStoreType === "branch"
                                ? "flex flex-col"
                                : "hidden"
                            }`}
                          >
                            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                              Select Branch
                              <span className="text-red-600"></span>
                            </label>
                            <Select
                              placeholder="Select Branch"
                              optionFilterProp="children"
                              className="wow"
                              style={{ marginBottom: "1rem" }}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              size="middle"
                              onChange={(value) => setBranchId(value)}
                              options={branches}
                              value={branchId}
                            />
                          </div>
                          <div
                            className={`${
                              productStoreType === null ||
                              (productStoreType === "branch" &&
                                branchId === null)
                                ? "hidden"
                                : "flex flex-col"
                            }`}
                          >
                            <div className="flex flex-col">
                              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                                Store <span className="text-red-600"></span>
                              </label>

                              <input
                                type="text"
                                placeholder="Store"
                                value={location?.store}
                                onChange={(e) =>
                                  setLocation({
                                    ...location,
                                    store: e.target.value,
                                  })
                                }
                                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                                Asile <span className="text-red-600"></span>
                              </label>
                              <input
                                type="text"
                                placeholder="Asile"
                                value={location?.aisle}
                                onChange={(e) =>
                                  setLocation({
                                    ...location,
                                    aisle: e.target.value,
                                  })
                                }
                                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                                Rack <span className="text-red-600"></span>
                              </label>
                              <input
                                type="text"
                                placeholder="Rack"
                                value={location?.rack}
                                onChange={(e) =>
                                  setLocation({
                                    ...location,
                                    rack: e.target.value,
                                  })
                                }
                                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                                Shelf <span className="text-red-600"></span>
                              </label>
                              <input
                                type="text"
                                placeholder="Shelf"
                                value={location?.shelf}
                                onChange={(e) =>
                                  setLocation({
                                    ...location,
                                    shelf: e.target.value,
                                  })
                                }
                                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                              />
                            </div>
                            <div className="flex flex-col">
                              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                                Bin <span className="text-red-600"></span>
                              </label>
                              <input
                                type="text"
                                placeholder="Bin"
                                value={location?.bin}
                                onChange={(e) =>
                                  setLocation({
                                    ...location,
                                    bin: e.target.value,
                                  })
                                }
                                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-end justify-between bg-[#F6F4F4] p-4 rounded-md mt-4">
                        <h1 className="text-gray-600 text-[0.95rem] font-semibold">
                          Discount
                        </h1>
                        <p
                          className="text-[#596F96] text-[0.88rem] font-semibold cursor-pointer"
                          onClick={() => {
                            setActiveDiscount(!activeDiscount);
                          }}
                        >
                          {activeDiscount ? (
                            <RightOutlined />
                          ) : (
                            <DownOutlined />
                          )}
                        </p>
                      </div>

                      <div
                        className={`${
                          activeDiscount
                            ? "flex flex-col mt-4 bg-white p-4 rounded-md"
                            : "hidden"
                        }`}
                      >
                        <div
                          className={`${
                            editProduct
                              ? "flex items-center justify-end gap-2 "
                              : "hidden"
                          }`}
                        >
                          <p
                            className="text-gray-600 font-semibold text-[0.9rem] cursor-pointer"
                            onClick={() => {
                              router("/Inventory/ProductDetail/Discounts");
                            }}
                          >
                            View Discount History
                          </p>
                          <img src={discountHis} alt="" />
                        </div>
                        <div className="flex flex-col">
                          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                            Discount Name<span className="text-red-600"></span>
                          </label>
                          <input
                            type="text"
                            placeholder="Discount Name"
                            value={discountName}
                            onChange={(e) => setDiscountName(e.target.value)}
                            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                          />
                        </div>
                        <div className="flex gap-3 w-full  ">
                          <div className="flex-1 flex-col">
                            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                              Discount Type{" "}
                              <span className="text-red-600"></span>
                            </label>
                            <Select
                              placeholder="Discount Type"
                              optionFilterProp="children"
                              className="wow"
                              style={{ marginTop: "0.5rem" }}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              size="middle"
                              options={[
                                {
                                  label: "Percentage",
                                  value: "percent",
                                },
                                {
                                  label: "Fixed",
                                  value: "fixed",
                                },
                              ]}
                              onChange={(e) => setDiscountType(e)}
                            />
                          </div>
                          <div className="flex-1 flex-col ">
                            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                              Discount <span className="text-red-600 "></span>
                            </label>
                            <input
                              type="text"
                              placeholder="Discount "
                              style={{ marginTop: "0.5rem" }}
                              value={discountValue}
                              onChange={(e) => setDiscountValue(e.target.value)}
                              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                            />
                          </div>
                        </div>
                        <div className="flex gap-3 w-full mb-4 ">
                          <div className="flex-1 flex-col">
                            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                              Discount Start{" "}
                              <span className="text-red-600"></span>
                            </label>
                            <DatePicker
                              placeholder="Discount Start"
                              style={{ marginTop: "0.5rem" }}
                              disabledDate={disabledDate1}
                              className="date-class  h-[2.7rem]"
                              onChange={(e, f) => setDiscountStartDate(f)}
                            />
                          </div>
                          <div className="flex-1 flex-col ">
                            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                              Discount End{" "}
                              <span className="text-red-600"></span>
                            </label>
                            <DatePicker
                              placeholder="Discount End"
                              style={{ marginTop: "0.5rem" }}
                              disabledDate={disabledDate1}
                              className="date-class  h-[2.7rem]"
                              onChange={(e, f) => setDiscountEndDate(f)}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1">
                            Discount Description{" "}
                            <span className="text-red-600"></span>
                          </label>
                          <textarea
                            type="text"
                            placeholder=" Discount Description"
                            value={discountDescription}
                            onChange={(e) =>
                              setDiscountDescription(e.target.value)
                            }
                            className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                          />
                        </div>
                        <div
                          className={`${
                            editProduct
                              ? "flex items-center justify-center mt-2 gap-5"
                              : "hidden"
                          }`}
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();

                              if (discountName === "") {
                                setErr("Discount name is required");
                                setShowErrorModal(true);
                                return;
                              }
                              if (discountValue === "") {
                                setErr("Discount value is required");
                                setShowErrorModal(true);
                                return;
                              }
                              if (discountStartDate === "") {
                                setErr("Discount start date is required");
                                setShowErrorModal(true);
                                return;
                              }
                              if (discountEndDate === "") {
                                setErr("Discount end date is required");
                                setShowErrorModal(true);
                                return;
                              }
                              if (discountDescription === "") {
                                setErr("Discount description is required");
                                setShowErrorModal(true);
                                return;
                              }
                              AddDiscount(ProductId);
                            }}
                            className="bg-primary text-white p-2 rounded-md cursor-pointer w-[14rem]"
                          >
                            Add Discount
                          </button>
                        </div>
                      </div>
                      <div className="flex items-center mt-6 gap-5">
                        <Checkbox
                          className={"checkk3"}
                          checked={returnable}
                          onClick={() => {
                            setReturnable(!returnable);
                          }}
                        >
                          Returnable
                        </Checkbox>
                        <Checkbox
                          className={"checkk3"}
                          checked={exchangable}
                          onClick={() => {
                            setExchangable(!exchangable);
                          }}
                        >
                          Exchange
                        </Checkbox>
                      </div>
                      <div className="flex items-center mt-3 gap-5">
                        <input
                          type="text"
                          placeholder="Returnable Days"
                          value={returnableDays}
                          onChange={(e) => setReturnableDays(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                        <input
                          type="text"
                          placeholder="Exchange Days"
                          value={exchangableDays}
                          onChange={(e) => setExchangableDays(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>

                      <div className="flex-1 flex-col mt-3">
                        <div className="flex items-end justify-between bg-[#F6F4F4] p-4 rounded-md mt-4">
                          <h1 className="text-gray-600 text-[0.95rem] font-semibold">
                            Taxable Product
                          </h1>
                          <Switch
                            // checkedChildren="Whitelist"
                            // unCheckedChildren="Blacklist"
                            // className="absolute top-4 right-4"
                            size="8px"
                            style={{
                              fontSize: "2px",
                              backgroundColor:
                                taxable === true ? "#1BA397" : "#c3c6c9",
                            }}
                            checked={taxable === true}
                            onClick={() => {
                              setTaxable(!taxable);
                            }}
                          />
                        </div>
                        <div className={`${taxable ? "block " : "hidden"}`}>
                          <div className="flex flex-col">
                            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2 mt-2">
                              Select Taxes{" "}
                              <span className="text-red-600"></span>
                            </label>
                            <div className="bg-[#F6F4F4] p-4 rounded-md">
                              <div className="grid grid-cols-4 gap-2 p-2">
                                {defaultTaxes?.map((item, index) => (
                                  <Checkbox
                                    className="checkk3"
                                    checked={taxes.includes(item.value)}
                                    onClick={() => {
                                      setTaxes((prevTaxes) => {
                                        if (prevTaxes.includes(item.value)) {
                                          return prevTaxes.filter(
                                            (id) => id !== item.value
                                          );
                                        } else {
                                          return [...prevTaxes, item.value];
                                        }
                                      });
                                    }}
                                  >
                                    {item?.label}
                                  </Checkbox>
                                ))}
                              </div>
                              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2 mt-4">
                                Additional Taxes
                                <span className="text-red-600"></span>
                              </label>
                              <div className="grid grid-cols-4 gap-2 p-2">
                                {additionalTaxes?.map((item, index) => (
                                  <Checkbox
                                    className="checkk3"
                                    checked={taxes.includes(item.value)}
                                    onClick={() => {
                                      setTaxes((prevTaxes) => {
                                        if (prevTaxes.includes(item.value)) {
                                          return prevTaxes.filter(
                                            (id) => id !== item.value
                                          );
                                        } else {
                                          return [...prevTaxes, item.value];
                                        }
                                      });
                                    }}
                                  >
                                    {item?.label}
                                  </Checkbox>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Warranty (no of Months){" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Warranty"
                          value={warranty}
                          onChange={(e) => setWarranty(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                        {/* <Select
                          placeholder="Warranty"
                          optionFilterProp="children"
                          className="wow"
                          style={{ marginBottom: "1rem" }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          onChange={(value) => setWarranty(value)}
                          options={[
                            {
                              value: 1,
                              label: "1 Month",
                            },
                            {
                              value: 2,
                              label: "2 Months",
                            },
                            {
                              value: 3,
                              label: "3 Months",
                            },
                            {
                              value: 4,
                              label: "4 Months",
                            },
                          ]}
                        /> */}
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Cost <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Cost"
                          value={cost}
                          onChange={(e) => setCost(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                      <div className="flex ">
                        <div className="flex flex-col w-full">
                          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                            Selling Price{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <div className="flex w-full gap-3">
                            <input
                              type="text"
                              placeholder="Selling Price"
                              value={basePrice}
                              onChange={(e) => setBasePrice(e.target.value)}
                              className="flex-1 lg:text-[1.1rem] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                            />
                            <button
                              className={`${
                                editProduct
                                  ? "flex-1 border-[1px] border-primary lg:text-[1.1rem] bg-white text-primary rounded-[0.5rem] p-2 mb-4"
                                  : "hidden"
                              }`}
                              onClick={() => {
                                router("/Inventory/AddProduct/AddTier");
                              }}
                            >
                              Set Up Tier Pricing
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Pricing Type <span className="text-red-600">*</span>
                        </label>
                        <Select
                          placeholder="Pricing Type"
                          optionFilterProp="children"
                          className="wow"
                          style={{ marginBottom: "1rem" }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          onChange={(value) => setPricingType(value)}
                          value={pricingType}
                          options={[
                            {
                              value: "per-item",
                              label: "Per Item",
                            },
                            {
                              value: "per-weight",
                              label: "Per Weight",
                            },
                          ]}
                        />
                      </div>

                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Expiry Date <span className="text-red-600">*</span>
                        </label>
                        <DatePicker
                          placeholder="Expiry Date"
                          style={{ marginBottom: "1.2rem" }}
                          disabledDate={disabledDate1}
                          className="date-class  h-[2.7rem]"
                          onChange={(e, f) => {
                            setExpiryDate(f);
                          }}
                          value={expiryDate ? dayjs(expiryDate) : null}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Minimum Order Quantity{" "}
                          <span className="text-red-600"></span>
                        </label>
                        <input
                          type="text"
                          placeholder="Minimum Order Quantity"
                          value={minimumOrderQty}
                          onChange={(e) => setMinimumOrderQty(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>

                      <div className="flex-1 flex-col mt-2 mb-3">
                        <div className="flex items-end justify-between bg-[#F6F4F4] p-4 rounded-md mt-2 mb-2">
                          <div>
                            <h1 className="text-gray-600 text-[0.95rem] font-semibold">
                              Free Delivery ( Conditions apply)
                            </h1>
                          </div>

                          <Switch
                            // checkedChildren="Whitelist"
                            // unCheckedChildren="Blacklist"
                            // className="absolute top-4 right-4"
                            size="8px"
                            style={{
                              fontSize: "2px",
                              backgroundColor:
                                freeDelivery === true ? "#1BA397" : "#c3c6c9",
                            }}
                            checked={freeDelivery === true}
                            onClick={() => {
                              setFreeDelivery(!freeDelivery);
                            }}
                          />
                        </div>
                        <label className="text-[#7F7A7A] text-[0.88rem]  mb-2 mt-2">
                          <p className="mb-2">
                            You can set free delivery for some specific
                            conditions. If these conditions are not met, the
                            customer will be charged delivery fees on a per-unit
                            basis
                          </p>
                          <span className="text-red-600"></span>
                        </label>
                        <div
                          className={`${freeDelivery ? "block " : "hidden"}`}
                        >
                          <div className="flex flex-col">
                            {/* <label className="text-[#7F7A7A] text-[0.88rem]  mb-2 mt-2">
                              <p>
                                You can set free delivery for some specific
                                conditions. If these conditions are not met, the
                                customer will be charged delivery fees on a
                                per-unit basis
                              </p>
                              <span className="text-red-600"></span>
                            </label> */}
                            <div className="flex flex-col">
                              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                                Select Condition for free delivery{" "}
                                <span className="text-red-600">*</span>
                              </label>
                              <Select
                                placeholder="Minimum quantity "
                                optionFilterProp="children"
                                className="wow"
                                style={{ marginBottom: "1rem" }}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                size="middle"
                                onChange={(value) =>
                                  setFreeDeliveryCondition(value)
                                }
                                value={freeDeliveryCondition}
                                options={[
                                  {
                                    value: "by-units",
                                    label: "By Units",
                                  },
                                  {
                                    value: "by-amount",
                                    label: "By Amount",
                                  },
                                ]}
                              />
                              <input
                                type="text"
                                placeholder="Quantity"
                                value={freeDeliveryConditionValue}
                                onChange={(e) =>
                                  setFreeDeliveryConditionValue(e.target.value)
                                }
                                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Delivery Charges (Per unit cost ){" "}
                          <span className="text-red-600"></span>
                        </label>
                        <input
                          type="text"
                          placeholder="Delivery Charges (Per unit cost )"
                          value={deliveryFeePerUnit}
                          onChange={(e) =>
                            setDeliveryFeePerUnit(e.target.value)
                          }
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>

                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Unit <span className="text-red-600">*</span>
                        </label>
                        <Select
                          placeholder="Unit"
                          optionFilterProp="children"
                          className="wow"
                          style={{ marginBottom: "1rem" }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          onChange={(value) => setPricingUnit(value)}
                          value={pricingUnit}
                          options={[
                            { label: "Kilograms", value: "kg" },
                            { label: "Grams", value: "g" },
                            { label: "Milligrams", value: "mg" },
                            { label: "Pounds", value: "lb" },
                            { label: "Ounces", value: "oz" },
                            { label: "Liters", value: "ltr" },
                            { label: "Milliliters", value: "ml" },
                            { label: "Gallons", value: "gal" },
                            { label: "Pieces", value: "nos" },
                            { label: "Packets", value: "pk" },
                            { label: "Boxes", value: "box" },
                            { label: "Bottles", value: "btl" },
                            { label: "Cans", value: "can" },
                            { label: "Dozens", value: "dz" },
                            { label: "Meters", value: "m" },
                            { label: "Centimeters", value: "cm" },
                            { label: "Millimeters", value: "mm" },
                            { label: "Inches", value: "in" },
                            { label: "Square Meters", value: "sqm" },
                            { label: "Square Feet", value: "sqft" },
                            { label: "Yards", value: "yd" },
                            { label: "Sets", value: "set" },
                            { label: "Pairs", value: "pair" },
                            { label: "Rolls", value: "roll" },
                            { label: "Bags", value: "bag" },
                            { label: "Tins", value: "tin" },
                            { label: "Sheets", value: "sheet" },
                            { label: "Bundles", value: "bundle" },
                            { label: "Pieces", value: "pcs" },
                            { label: "Tubes", value: "tube" },
                            { label: "Cases", value: "case" },
                          ]}
                        />
                      </div>

                      <div className="w-full flex  flex-col ">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1">
                          Bar Code <span className="text-red-600">*</span>
                        </label>
                        <label
                          // htmlFor="special"
                          className="cursor-pointer w-full relative"
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Add Bar Code</p>
                          </div>
                          <input
                            type="file"
                            className="hidden w-[100%] cursor-pointer"
                            // id="special"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => imageHandlerE(e, "barCode")}
                          />
                        </label>
                        <div
                          className={`${
                            barCode === ""
                              ? "hidden"
                              : "relative w-full h-[8rem] overflow-hidden border bg-gray-200  rounded-md mb-4"
                          }`}
                        >
                          <img
                            className={`w-full h-full ${
                              barCode === "" ? "hidden" : ""
                            }`}
                            src={barCode}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setBarCode("");
                            }}
                          >
                            <img
                              className="sm:w-[3rem] w-[2rem]"
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex  flex-col ">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1">
                          Product Images (up to 3 images){" "}
                          <span className="text-red-600"></span>
                        </label>
                        <label
                          htmlFor="special"
                          className={`${
                            imageUrl.length >= 4
                              ? "hidden"
                              : "cursor-pointer w-full relative"
                          } `}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">
                              Add Product Images
                            </p>
                          </div>
                          <input
                            type="file"
                            className="hidden w-[100%] cursor-pointer"
                            id="special"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => imageHandlerE(e, "other")}
                          />
                        </label>
                        <div className=" grid grid-cols-3 gap-5">
                          {imageUrl

                            .filter((image) => !image.isCoverImg) // filter images where isCoverImg is false
                            .map((image, index) => (
                              <div
                                key={index + 1}
                                className="relative mb-4 border"
                              >
                                <img
                                  onClick={() => {}}
                                  className="w-full h-32 rounded-xl object-cover"
                                  src={image.image} // Access the image URL from the object
                                  alt=""
                                />
                                <div
                                  className="absolute bottom-0 right-0 bg-white cursor-pointer"
                                  onClick={() => {
                                    if (editProduct) {
                                      DeleteImage(image?.id);
                                      return;
                                    }
                                    setImageUrl(
                                      imageUrl.filter((img) => img !== image)
                                    );
                                  }}
                                >
                                  <img
                                    className="sm:w-[3rem] w-[2rem]"
                                    src={trash}
                                    alt="delete"
                                  />
                                </div>
                              </div>
                            ))}

                          {/* {images.slice(1).map((image, index) => (
                            <div
                              key={index + 1}
                              className="relative mb-4 border"
                            >
                              <img
                                onClick={() => {}}
                                className="w-full h-32 rounded-xl object-cove"
                                src={image}
                                alt=""
                              />
                              <div
                                className="absolute bottom-0 right-0 bg-white cursor-pointer"
                                onClick={() => {
                                  setImages(
                                    images.filter((_, i) => i !== index + 1)
                                  );
                                }}
                              >
                                <img
                                  className="sm:w-[3rem] w-[2rem]"
                                  src={trash}
                                  alt=""
                                />
                              </div>
                            </div>
                          ))} */}
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1">
                          Description <span className="text-red-600">*</span>
                        </label>
                        <textarea
                          type="text"
                          placeholder=" Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-full flex items-center justify-center flex-col mt-5">
                    <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1">
                      Product Images <span className="text-red-600"></span>
                    </label>
                    <label
                      htmlFor="special"
                      className="cursor-pointer w-full relative bg-red-600"
                    >
                      <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                        <div>
                          <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                        </div>
                        <p className="text-[#9CA6C2] ml-2">
                          Add Product Images
                        </p>
                      </div>
                      <input
                        type="file"
                        className="hidden w-[100%] cursor-pointer"
                        id="special"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={imageHandlerE}
                      />
                    </label>
                    <div className=" sm:w-[70%] w-[80%] flex gap-4 justify-between flex-wrap">
                      {images.map((image, index) => (
                        <div key={index} className="relative mb-4 w-[30%]">
                          <img
                            onClick={() => {}}
                            className="w-full h-48 rounded-xl object-cove"
                            src={image}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setImages(images.filter((_, i) => i !== index));
                            }}
                          >
                            <img
                              className="sm:w-[3rem] w-[2rem]"
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>

                <div className="flex w-full items-center justify-center">
                  <div
                    className={`lg:mt-[3rem] flex mt-4 items-center justify-center sm:w-[80%]`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        AddProduct();
                        // setShowAddTier(true);
                      }}
                      className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                    >
                      {editProduct ? "Update" : "Add Product"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
      {showSuccessModal && (
        <SuccessModal
          setShow={setShowSuccessModal}
          err={err}
          route={"/Inventory"}
        />
      )}
      {showAddTier && (
        <AddTierModal
          setShow={setShowAddTier}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
    </>
  );
};

export default Mid;
